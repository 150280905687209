import axios from "axios";

// export const BaseUrl = "https://knsonline.in/dev/accounts/";
// export const ErpBaseUrl = "http://192.168.0.200/dev/accounts/";
// export const DocumentUrl = "https://knsonline.in/dev/";

// export const BaseUrl = "https://knsonline.in/dev/RestAPI_V1/";
// export const ErpBaseUrl = "http://192.168.0.200/dev/RestAPI_V1/";
// export const DocumentUrl = "https://knsonline.in/dev/";

// export const BaseUrl = "https://knsonline.in/test/RestAPI_V1/";
// export const ErpBaseUrl = "http://192.168.0.200/test/RestAPI_V1/";
// export const DocumentUrl = "https://knsonline.in/test/";

export const BaseUrl = "https://knsonline.in/uat/RestAPI_V1/";
export const ErpBaseUrl = "http://192.168.0.200/uat/RestAPI_V1/";
export const DocumentUrl = "https://knsonline.in/uat/";

// export const BaseUrl = "https://knsonline.in/RestAPI_V1/";
// export const ErpBaseUrl = "http://192.168.0.200/RestAPI_V1/";
// export const DocumentUrl = "https://knsonline.in/";

/* -------------------------------------------------------------------------- */
/*                               ApiPaths Start                               */
/* -------------------------------------------------------------------------- */

export const ApiPaths = {
  LOGIN: "userLogin",
  UPDATE_USER_PASSWORD: "updateUserPassword",
  GET_USER_MENU: "getUserMenu",
  GETDASHBOARDDATA: "getDashboardData",
  GET_DASHBOARD_DATA_PRE_SALES: "getDashboardDataPreSales",
  PROFITABLE_REPORTS: "getProcessPlan",
  LIASONPROCESSOWNER: "getLiasonProcessOwner1",
  GETPROJECTS: "getProjects",
  GETTASKDELAY: "getTaskDelayReason",
  GETPENDINGTASK: "getPendingTasks",
  GETCOURTCASEPENDINGREM: "getCourtCasePendingReminder",
  GETCOURTCASEREM: "getCourtCaseReminder",
  PENDINGLIAISONREPORT: "getLiaisonReport",
  PENDINGLIAISONREPORT_1: "getLiaisonReport1",
  CIVILRESOURCEPLANNING: "getCivilResourcePlanning",
  CIVILRESOURCETYPEPLANNING: "getCivilTaskOveralResourcePlanning",
  GETCIVILPROJECTS: "getCivilProjectPlan",
  GETCIVILPROCESS: "getCivilProcessPlan",
  GETCIVILTASKS: "getCivilTaskPlan",
  GETCIVILRESOURCE: "getCivilProjectResource",
  GETCIVILBUDGET: "getCivilTaskBudget",
  GETCIVILPROGRESS: "getCivilProjectProgress",
  GET_MY_DASHBOARD_DATA: "getMyDashBoardData",
  GET_MY_DASHBOARD_TASK: "getMyDashBoardTask",
  GET_CIVIL_PROCESS_DATA: "getCivilProcessData",
  GET_MY_DASHBOARD_DATA_FILTER_USER: "getMyDashBoardDataFilterUser",
  // __________________ BD Chart 1 and Chart 2 ______________________
  // for Mou
  GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_ZONE_DATA:
    "getDashboardAquiredButPlanApprovalPendingZoneData",
  GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_PROJECT_DATA:
    "getDashboardAquiredButPlanApprovalPendingProjectData",
  GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_FILE_DATA:
    "getDashboardAquiredButPlanApprovalPendingFileData",
  // for Plan Approval
  GET_DASHBOARD_PLAN_APPROVAL_APPLIED_ZONE_DATA:
    "getDashboardPlanApprovalAppliedZoneData",
  GET_DASHBOARD_PLAN_APPROVAL_APPLIED_PROJECT_DATA:
    "getDashboardPlanApprovalAppliedProjectData",
  GET_DASHBOARD_PLAN_APPROVAL_APPLIED_FILE_DATA:
    "getDashboardPlanApprovalAppliedFileData",
  // for Sale Inventory
  GET_DASHBOARD_STOCK_ZONE_DATA: "getDashboardStockZoneData",
  GET_DASHBOARD_STOCK_PROJECT_DATA: "getDashboardStockProjectData",
  GET_DASHBOARD_STOCK_PROJECT_SITE_DATA: "getDashboardStockProjectSiteData",
  //  _________________ BD Chart 3 _____________
  GET_DASHBOARD_STOCK_AGING_ZONE_DATA: "getDashboardStockAgingZoneData",
  GET_DASHBOARD_STOCK_AGING_PROJECT_DATA: "getDashboardStockAgingProjectData",
  GET_DASHBOARD_STOCK_AGING_FILE_DATA: "getDashboardStockAgingFileData",
  // __________________ BD Chart 4 _____________
  GET_DASHBOARD_LAND_AQUIRED_ZONE_DATA: "getDashboardLandAquiredZoneData",
  GET_DASHBOARD_LAND_AQUIRED_PROJECT_DATA: "getDashboardLandAquiredProjectData",
  GET_DASHBOARD_LAND_AQUIRED_FILE_DATA: "getDashboardLandAquiredFileData",
  // __________________ BD Chart 5 _____________
  GET_DASHBOARD_APPLAN_APPROVAL_ZONE_DATA: "getDashboardApplanApprovalZoneData",
  GET_DASHBOARD_APPLAN_APPROVAL_PROJECT_DATA:
    "getDashboardApplanApprovalProjectData",
  GET_DASHBOARD_APPLAN_APPROVAL_FILE_DATA: "getDashboardApplanApprovalFileData",
  // __________________seperate BD Chart 6 _____________
  GET_DASHBOARD_LAND_AQUIRED_FINANCIAL_YEAR_DATA:
    "getDashboardLandAquiredFinancialYearData",
  GET_DASHBOARD_PLAN_APPROVAL_FINANCIAL_YEAR_DATA:
    "getDashboardApplanApprovalFinancialYearData",
  GET_DASHBOARD_PLAN_APPROVED_TARGET_ACTUAL_FINANCIAL_YEAR_DATA:
    "getDashboardApplanApprovedTargetActualFinancialYearData",
  GET_DASHBOARD_DATA_DEPARTMENT: "getDashboardDataDepartment",
  GET_LIAISON_PROCESS_PLAN_REPORT: "getLiaisonProcessPlanReport",
  GET_LIAISON_PROCESS_PLAN_REPORT_DETAIL: "getLiaisonProcessPlanReportDetail",
  GET_DASHBOARD_DEPARTMENT: "getDashboardDepartment",

  // ______________ DASHBOARD ________________
  //Main Dashboard (Sadanand)
  GET_DASHBOARD_DEPARTMENT_DATA: "getDashboardDepartmentData",
  //My Dashboard (Sadanand)
  GET_PENDING_DASHBOARD_DATA: "getPendingDashboardData",
  //Accounable (Sadanand)
  GET_PENDING_RESPONSIBLE_DASHBOARD_DATA: "getPendingResponsibleDashboardData",
  //Resposnible (Sadanand)
  GET_PENDING_ACCOUNTABLE_DASHBOARD_DATA: "getPendingAccountableDashboardData",

  //Liaison Process (Sadanand)
  GET_DASHBOARD_DATA_LIAISON_PROCESS_PLAN: "getDashboardDataLiaisonProcessPlan",
  GET_DASHBOARD_DATA_LIAISON_PROCESS_PLAN_FILTER_PROJECT:
    "getDashboardDataLiaisonProcessPlanFilterProject",
  GET_DASHBOARD_DATA_LIAISON_PROCESS_PLAN_FILTER_PROCESS:
    "getDashboardDataLiaisonProcessPlanFilterProcess",
  GET_DASHBOARD_DATA_LIAISON_PROCESS_PLAN_FILTER_ACCOUNTABLE:
    "getDashboardDataLiaisonProcessPlanFilterAccountable",
  GET_DASHBOARD_DATA_LIAISON_PROCESS_PLAN_FILTER_RESPONSIBLE:
    "getDashboardDataLiaisonProcessPlanFilterResponsible",

  //Liaison Documentation (Sadanand)
  GET_DASHBOARD_DATA_DOCUMENTATION_PLAN: "getDashboardDataDocumentationPlan",
  GET_DASHBOARD_DATA_LIAISON_DOCUMENTATION_FILTER_PROJECT:
    "getDashboardDataDocumentationPlanFilterProject",
  GET_DASHBOARD_DATA_LIAISON_DOCUMENTATION_FILTER_CATEGORY:
    "getDashboardDataDocumentationPlanFilterCategory",
  GET_DASHBOARD_DATA_LIAISON_DOCUMENTATION_FILTER_ACCOUNTABLE:
    "getDashboardDataDocumentationPlanFilterAccountable",
  GET_DASHBOARD_DATA_LIAISON_DOCUMENTATION_FILTER_RESPONSIBLE:
    "getDashboardDataDocumentationPlanFilterResponsible",

  //Legal Task Filter (Sadanand)
  GET_DASHBOARD_DATA_BD_LEGAL_TASK: "getDashboardDataBdLegalTask",
  GET_DASHBOARD_DATA_BD_LEGAL_TASK_FILTER_PROJECT:
    "getDashboardDataBdLegalTaskFilterProject",
  GET_DASHBOARD_DATA_BD_LEGAL_TASK_FILTER_ACCOUNTABLE:
    "getDashboardDataBdLegalTaskFilterAccountable",
  GET_DASHBOARD_DATA_BD_LEGAL_TASK_FILTER_RESPONSIBLE:
    "getDashboardDataBdLegalTaskFilterResponsible",

  //Boundary Dispute
  // GET_DASHBOARD_DATA_BD_LEGAL_TASK_FILTER_PROJECT:
  //   "getDashboardDataDocumentationPlanFilterProject",
  // GET_DASHBOARD_DATA_BD_LEGAL_TASK_FILTER_ACCOUNTABLE:
  //   "getDashboardDataDocumentationPlanFilterAccountable",
  // GET_DASHBOARD_DATA_BD_LEGAL_TASK_FILTER_RESPONSIBLE:
  //   "getDashboardDataDocumentationPlanFilterResponsible",

  //Apf Query (Sadanand)
  GET_DASHBOARD_DATA_APF_TASK_PLAN_QUERY: "getDashboardDataApfTaskPlanQuery",
  GET_DASHBOARD_DATA_APF_TASK_PLAN_QUERY_FILTER_PROJECT:
    "getDashboardDataApfTaskPlanQueryFilterProject",
  GET_DASHBOARD_DATA_APF_TASK_PLAN_QUERY_FILTER_ACCOUNTABLE:
    "getDashboardDataApfTaskPlanQueryFilterAccountable",
  GET_DASHBOARD_DATA_APF_TASK_PLAN_QUERY_FILTER_RESPONSIBLE:
    "getDashboardDataApfTaskPlanQueryFilterResponsible",

  //Loan Query (Sadanand)
  GET_DASHBOARD_DATA_LOAN_PROCESS_PLAN_QUERY:
    "getDashboardDataLoanProcessPlanQuery",
  GET_DASHBOARD_DATA_LOAN_PROCESS_PLAN_QUERY_FILTER_PROJECT:
    "getDashboardDataLoanProcessPlanQueryFilterProject",
  GET_DASHBOARD_DATA_LOAN_PROCESS_PLAN_QUERY_FILTER_PROCESS:
    "getDashboardDataLoanProcessPlanQueryFilterProcess",
  GET_DASHBOARD_DATA_LOAN_PROCESS_PLAN_QUERY_FILTER_ACCOUNTABLE:
    "getDashboardDataLoanProcessPlanQueryFilterAccountable",
  GET_DASHBOARD_DATA_LOAN_PROCESS_PLAN_QUERY_FILTER_RESPONSIBLE:
    "getDashboardDataLoanProcessPlanQueryFilterResponsible",

  //To be Acquired (Sadanand)
  GET_DASHBOARD_DATA_PROJECT_FILE_ACQUISITION_PENDING:
    "getDashboardDataProjectFileAcquisitionPending",
  GET_DASHBOARD_DATA_PROJECT_FILE_ACQUISITION_PENDING_FILTER_PROJECT:
    "getDashboardDataDocumentationPlanFilterProject",
  GET_DASHBOARD_DATA_PROJECT_FILE_ACQUISITION_PENDING_FILTER_ACCOUNTABLE:
    "getDashboardDataDocumentationPlanFilterAccountable",
  GET_DASHBOARD_DATA_PROJECT_FILE_ACQUISITION_PENDING_FILTER_RESPONSIBLE:
    "getDashboardDataDocumentationPlanFilterResponsible",

  //court case (Sadanand)
  GET_DASHBOARD_DATA_COURT_CASE: "getDashboardDataCourtCase",
  GET_DASHBOARD_DATA_COURT_CASE_FILTER_PROJECT: "getCourtCaseProjectFilter",
  GET_DASHBOARD_DATA_COURT_CASE_FILTER_ACCOUNTABLE:
    "getDashboardDataCourtCaseFilterAccountable",
  GET_DASHBOARD_DATA_COURT_CASE_FILTER_RESPONSIBLE:
    "getDashboardDataCourtCaseFilterResponsible",

  //To Do (Sadanand)
  GET_DASHBOARD_DATA_TODO: "getDashboardDataToDo",
  GET_DASHBOARD_DATA_TODO_FILTER_PROJECT: "getDashboardDataToDoFilterProject",
  GET_DASHBOARD_DATA_TODO_FILTER_ACCOUNTABLE:
    "getDashboardDataToDoFilterAccountable",
  GET_DASHBOARD_DATA_TODO_FILTER_RESPONSIBLE:
    "getDashboardDataToDoFilterResponsible",

  //Project Task (Sadanand)
  GET_DASHBOARD_DATA_PROJECT_TASK_PLAN: "getDashboardDataProjectTaskPlan",
  GET_DASHBOARD_DATA_PROJECT_TASK_PLAN_FILTER_PROJECT:
    "getDashboardDataProjectTaskPlanFilterProject",
  GET_DASHBOARD_DATA_PROJECT_TASK_PLAN_FILTER_TASK:
    "getDashboardDataProjectTaskPlanFilterTask",
  GET_DASHBOARD_DATA_PROJECT_TASK_PLAN_FILTER_ACCOUNTABLE:
    "getDashboardDataProjectTaskPlanFilterAccountable",
  GET_DASHBOARD_DATA_PROJECT_TASK_PLAN_FILTER_RESPONSIBLE:
    "getDashboardDataProjectTaskPlanFilterResponsible",

  //Survey (Sadanand)
  GET_DASHBOARD_DATA_PROJECT_SURVEY: "getDashboardDataProjectTaskPlan",
  GET_DASHBOARD_DATA_PROJECT_SURVEY_FILTER_PROJECT:
    "getDashboardDataProjectTaskPlanFilterProject",
  GET_DASHBOARD_DATA_PROJECT_SURVEY_FILTER_TASK:
    "getDashboardDataProjectTaskPlanFilterTask",
  GET_DASHBOARD_DATA_PROJECT_SURVEY_FILTER_ACCOUNTABLE:
    "getDashboardDataProjectTaskPlanFilterAccountable",
  GET_DASHBOARD_DATA_PROJECT_SURVEY_FILTER_RESPONSIBLE:
    "getDashboardDataProjectTaskPlanFilterResponsible",

  //Project Legal (Sadanand)
  GET_DASHBOARD_DATA_PROJECT_LEGAL: "getDashboardDataProjectTaskPlan",
  GET_DASHBOARD_DATA_PROJECT_LEGAL_FILTER_PROJECT:
    "getDashboardDataProjectTaskPlanFilterProject",
  GET_DASHBOARD_DATA_PROJECT_LEGAL_FILTER_TASK:
    "getDashboardDataProjectTaskPlanFilterTask",
  GET_DASHBOARD_DATA_PROJECT_LEGAL_FILTER_ACCOUNTABLE:
    "getDashboardDataProjectTaskPlanFilterAccountable",
  GET_DASHBOARD_DATA_PROJECT_LEGAL_FILTER_RESPONSIBLE:
    "getDashboardDataProjectTaskPlanFilterResponsible",

  //Marketing (Sadanand)
  //Enquiry Generated
  GET_DASHBOARD_DATA_ENQUIRY: "getDashboardDataEnquiry",
  GET_DASHBOARD_DATA_ENQUIRY_PROJECT: "getDashboardDataEnquiryProject",
  GET_DASHBOARD_DATA_ENQUIRY_SOURCE: "getDashboardDataEnquirySource",

  //Enquiry Qualificated (Sadanand)
  GET_DASHBOARD_DATA_ENQUIRY_QUALIFIED: "getDashboardDataEnquiryQualified",
  GET_DASHBOARD_DATA_ENQUIRY_QUALIFIED_PROJECT:
    "getDashboardDataEnquiryQualifiedProject",
  GET_DASHBOARD_DATA_ENQUIRY_QUALIFIED_SOURCE:
    "getDashboardDataEnquiryQualifiedSource",
  GET_DASHBOARD_DATA_ENQUIRY_QUALIFIED_PRE_SALES:
    "getDashboardDataEnquiryQualifiedPreSales",

  //Pre-Sales (Sadanand)
  //Site Visit
  GET_DASHBOARD_DATA_SITE_VISIT: "getDashboardDataSiteVisit",
  GET_DASHBOARD_DATA_SITE_VISIT_PROJECT: "getDashboardDataSiteVisitProject",
  GET_DASHBOARD_DATA_SITE_VISIT_SOURCE: "getDashboardDataSiteVisitSource",
  GET_DASHBOARD_DATA_SITE_VISIT_STM: "getDashboardDataSiteVisitStm",
  GET_DASHBOARD_DATA_SITE_VISIT_PRE_SALES: "getDashboardDataSiteVisitPreSales",

  //Sales (Sadanand)
  //Sales
  GET_DASHBOARD_DATA_SALES_BOOKING: "getDashboardDataSalesBooking",
  GET_DASHBOARD_DATA_SALES_BOOKIG_FILTER_PROJECT:
    "getDashboardDataSalesBookingFilterProject",
  GET_DASHBOARD_DATA_SALES_SOURCE: "getDashboardDataSalesSource",
  GET_DASHBOARD_DATA_SALES_BOOKIG_FILTER_BOOKEDBY:
    "getDashboardDataSalesBookingFilterBookedBy",

  //Cancellation
  GET_DASHBOARD_DATA_CANCELLATION: "getDashboardDataSalesCancellation",
  GET_DASHBOARD_DATA_CANCELLATION_PROJECT: "getDashboardCancellationProject",
  GET_DASHBOARD_DATA_CANCELLATION_SOURCE: "getDashboardCancellationSource",
  GET_DASHBOARD_DATA_CANCELLATION_STM: "getDashboardCancellationStm",

  //CRM (Sadanand)
  //Collection
  GET_DASHBOARD_DATA_COLLECTION: "getDashboardCollection",
  GET_DASHBOARD_DATA_COLLECTION_PROJECT: "getDashboardCollectionProject",
  GET_DASHBOARD_DATA_COLLECTION_USER: "getDashboardCollectionUser",

  //Agreement
  GET_DASHBOARD_DATA_AGREEMENT: "getDashboardAgreement",
  GET_DASHBOARD_DATA_AGREEMENT_PROJECT: "getDashboardAgreementProject",
  GET_DASHBOARD_DATA_AGREEMENT_USER: "getDashboardAgreementUser",

  //Registration
  GET_DASHBOARD_DATA_REGISTRATION: "getDashboardRegistration",
  GET_DASHBOARD_DATA_REGISTRATION_PROJECT: "getDashboardRegistrationProject",
  GET_DASHBOARD_DATA_REGISTRATION_USER: "getDashboardRegistrationUser",

  // __________________CRM________________
  // CRM Dashboard ((Sadanand))
  GET_DASHBOARD_CRM_PROJECT_OUTSTANDING: "getDashboardCrmProjectOutstanding",
  GET_DASHBOARD_CRM_SITE_OUTSTANDING: "getDashboardCrmSiteOutstanding",
  GET_DASHBOARD_CRM_PROJECT_TARGET: "getDashboardCrmProjectTarget",
  GET_DASHBOEAR_CRM_SITE_TARGET: "getDashboardCrmSiteTarget",
  GET_DASHBOARD_CRM_PROJECT_RECEIVED: "getDashboardCrmProjectReceived",
  GET_DASHBOARD_CRM_SITE_RECEIVED: "getDashboardCrmSiteReceived",
  GET_DASHBOARD_CRM_PROJECT_REALIZED: "getDashboardCrmProjectRealized",
  GET_DAHSBOARD_CRM_SITE_REALIZED: "getDashboardCrmSiteRealized",
  GET_DASHBOARD_CRM_PROJECT_NOT_REALIZED: "getDashboardCrmProjectNotRealized",
  GET_DAHSBOARD_CRM_SITE_NOT_REALIZED: "getDashboardCrmSiteNotRealized",

  GET_DASHBOARD_DATA_MARKETING_FUNNEL: "getDashboardDataMarketingFunnel",
  GET_GRN_ON_TIME_REPORT: "getGRNOnTimeKPIReport",
  GET_VENDOR_LEAD_TIME_REPORT: "getVendorLeadTimeKPIReport",
  GET_STOCK_PROJECT: "getStockProject",
  GET_PURCHASE_INVOICE_ON_TIME_KPI: "getPurchaseInvoiceOnTimeKPI",
  GET_PURCHASE_INVOICE_ON_TIME_KPI_REPORT: "getPurchaseInvoiceOnTimeKPIReport",
  GET_MATERIAL_QUANTITY_REJECTION_KPI: "getMaterialQuantityRejectionKPI",
  GET_MATERIAL_QUANTITY_REJECTION_KPI_REPORT:
    "getMaterialQuantityRejectionKPIReport",
  GET_INDENT_PURCHASE_ORDER_KPI_REPORT: "getIndentPurchaseOrderKPIReport",
  GET_DASHBOARD_DATA_REGISTRATION_TURN_AROUND_TIME:
    "getDashboardDataRegistrationTurnaroundTime",
  GET_REGISTRATION_TURNAROUND_TIME_REPORT:
    "getRegistrationTurnaroundTimeReport",
  GET_REGISTRATION_TURNAROUND_TIME_REPORT_FILTER_PROJECT:
    "getRegistrationTurnaroundTimeReportFilterProject",
  GET_REGISTRATION_TURNAROUND_TIME_REPORT_FILTER_USER:
    "getRegistrationTurnaroundTimeReportFilterUser",
  GET_ON_TIME_DELIVERY_KPI: "getOnTimeDeliveryKPI",
  GET_ON_TIME_DELIVERY_KPI_REPORT: "getOnTimeDeliveryKPIReport",
  GET_PROCUREMENT_KPI_REPORT: "getProcurmentKpiReport",
  GET_SALES_CRM_REPORT: "getSalesCrmReport",
  GET_CRM_PROJECTS: "getCrmProjects",
  GET_CRM_SITE: "getCrmSite",
  GET_SCALE_TYPE: "getScaleType",
  GET_CRM_SITE_DIMENSION: "getCrmSiteDimension",

  GET_KPI_SALES_REPORT: "getKPISalesReport",
  GET_KPI_SALES_REPORT_EXECUTIVE_FILTER: "getKPISalesReportExecutiveFilter",
  GET_KPI_SALES_REPORT_DETAILS: "getKPISalesReportDetail",
  GET_KPI_SALES_REPORT_DETAILS_PROJECT_FILTER:
    "getKPISalesReportDetailProjectFilter",
  GET_KPI_SALES_PRE_REPORT: "getKPIPreSalesReport",
  GET_KPI_PRE_SALES_REPORT_EXECUTIVE_FILTER:
    "getKPIPreSalesReportExecutiveFilter",
  GET_KPI_SALES_PRE_REPORT_DETAILS: "getKPIPreSalesReportDetail",
  GET_KPI_PRE_SALES_REPORT_DETAILS_PROJECT_FILTER:
    "getKPIPreSalesReportDetailProjectFilter",
  GET_KPI_SALES_CRM_REPORT: "getKPISalesCrmReport",
  //Sales Report Crm filter
  GET_KPI_SALES_CRM_REPORT_PROJECT_FILTER: "getKPISalesCrmReportProjectFilter",
  GET_KPI_SALES_REPORT_CRM_BOOKING_DETAIL_FILTER_PROJECT:
    "getKPISalesReportCrmBookingDetailFilterProject",
  GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAIL_FILTER_PROJECT:
    "getKPISalesReportCrmCancellationDetailFilterProject",
  GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL_FILTER_PROJECT:
    "getKPISalesReportSiteVisitDetailFilterProject",
  GET_KPI_SALES_CRM_REPORT_SOURCE_FILTER: "getEnquirySource",
  GET_KPI_SALES_CRM_REPORT_SALES_EXECUTIVE_FILTER:
    "getKPISalesCrmReportExecutiveFilter",
  GET_KPI_SALES_REPORT_CRM_BOOKING_DETAILS: "getKPISalesReportCrmBookingDetail",
  GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAILS:
    "getKPISalesReportCrmCancellationDetail",
  GET_KPI_SALES_REPORT2: "getKPISalesReport2",
  GET_KPI_SALES_SUMMARY_REPORT: "getKPISalesSummaryReport",
  GET_KPI_SALES_SUMMARY_REPORT_DETAILS: "getKPISalesSummaryReportDetail",
  GET_KPI_TELECALLING_REPORT: "getKPITelecallingReport",
  GET_KPI_SALES_REPORT_LEAD_ASSIGNED_DETAIL: "getKPIPreSalesReportLead",
  GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL: "getKPISalesReportSiteVisitDetail",
  GET_CAMPAIGN_REPORT: "getCampaignReport",
  GET_CAMPAIGN_REPORT_FILTER_CAMPAIGN: "getCampaignReportFilterCampaign",
  GET_CAMPAIGN_PROJECT_REPORT: "getCampaignProjectReport",
  GET_CAMPAIGN_PROJECT_REPORT_FILTER_PROJECT:
    "getCampaignProjectReportFilterProject",
  GET_CAMPAIGN_ENQUIRY_REPORT: "getCampaignEnquiryReport",
  GET_CAMPAIGN_BOOKING_REPORT: "getCampaignBookingReport",
  GET_CAMPAIGN_BOOKING_CANCELLED_REPORT: "getCampaignBookingCancelledReport",
  GET_CAMPAIGN_SITE_VISIT_REPORT: "getCampaignSiteVisitReport",
  GET_FINANCIAL_YEAR: "getFinancialYear",
  GET_FINANCIAL_YEAR_SCALE_TYPE: "getFinancialYearScaleType",
  GET_FINANCIAL_YEAR_SCALE_VALUE: "getFinancialYearScaleValue",
  GET_PRE_SALES_SITE_VISIT: "getKPIPreSalesSiteVisit",
  GET_KPITELECALLING_FILTER_USER: "getKPITelecallingFilterUser",
  GET_KPI_SALES_SUMMARY_FILTER_USER: "getKPISalesSummaryFilterUser",
  //Sales Report (Sadanand)
  GET_SALES_REPORT: "getSalesReport",
  GET_SALES_REPORT_SITE_VISIT: "getSalesReportSiteVisit",
  GET_SALES_REPORT_FOLLOW_UP: "getSalesReportFollowUp",
  GET_SALES_REPORT_PLANNED_FOLLOW_UP: "getSalesReportPlannedFollowUp",
  GET_SALES_REPORT_PLANNED_SITE_VISIT: "getSalesReportPlannedSiteVisit",
  GET_SALES_REPORT_SITE_VISIT_FILTER_STM: "getSalesReportSiteVisitFilterStm",
  GET_SALES_REPORT_FOLLOW_UP_FILTER_STM: "getSalesReportFollowUpFilterStm",
  GET_SALES_REPORT_BOOKING_FILTER_STM: "getSalesReportBookingFilterStm",
  GET_SALES_REPORT_PROSPECTIVE_CLIENT: "getSalesReportProspectiveClient",
  GET_SALES_REPORT_BOOKING: "getSalesReportBooking",
  GET_SALES_REPORT_CANCELLATION: "getSalesReportCancellation",
  GET_SALES_REPORT_CANCELLATION_PREVIOUS: "getSalesReportCancellationPrevious",
  GET_SALES_REPORT_BOOKING_GROSS: "getSalesReportBookingGross",
  GET_SALES_REPORT_HOT_PROSPECTIVE_CLIENT: "getSalesReportHotProspectiveClient",
  GET_MONTH_WEEK: "getMonthWeek",
  GET_SALES_REPORT_TEAM: "getSalesReportTeam",
  GET_SALES_REPORT_FILTER_TEAM: "getSalesReportFilterTeam",
  GET_SALES_REPORT_FILTER_USER: "getSalesReportFilterUser",
  GET_SALES_REPORT_PLANNED_CP_MEETING: "getSalesReportPlannedCpMeeting",
  GET_SALES_REPORT_COMPLETED_CP_MEETING: "getSalesReportCompletedCpMeeting",
  GET_SALES_REPORT_PLANNED_CLIENT_MEETING: "getSalesReportPlannedClientMeeting",
  GET_SALES_REPORT_COMPLETED_CLIENT_MEETING:
    "getSalesReportCompletedClientMeeting",
  GET_SALES_REPORT_ENQUIRY_FOLLOW_UP: "getSalesReportEnquiryFollowUp",
  GET_SALES_REPORT_AGREEMENT: "getSalesReportAgreement",
  GET_SALES_REPORT_REGISTRATION: "getSalesReportRegistration",
  // CP Sales Report (Sadanand)
  GET_SALES_CHANNEL_PARTNER_REPORT: "getSalesChannelPartnerReport",
  GET_SALES_CHANNEL_PARTNER_PROJECT_REPORT:
    "getSalesChannelPartnerProjectReport",
  GET_SALES_CHANNEL_PARTNER_ENQUIRY_REPORT:
    "getSalesChannelPartnerEnquiryReport",
  GET_SALES_CHANNEL_PARTNER_SITE_VISIT_REPORT:
    "getSalesChannelPartnerSiteVisitReport",
  GET_SALES_CHANNEL_PARTNER_BOOKING_REPORT:
    "getSalesChannelPartnerBookingReport",
  GET_SALES_CHANNEL_PARTNER_CANCELLATION_REPORT:
    "getSalesChannelPartnerCancellationReport",
  GET_SALES_CHANNEL_PARTNER_PROJECT_REPORT_FILTER_PROJECT:
    "getSalesChannelPartnerProjectReportFilterProject",
  GET_SALES_CHANNEL_PARTNER_ENQUIRY_REPORT_FILTER_PROJECT:
    "getSalesChannelPartnerEnquiryReportFilterProject",
  GET_SALES_CHANNEL_PARTNER_SITE_VISIT_REPORT_FILTER_PROJECT:
    "getSalesChannelPartnerSiteVisitReportFilterProject",
  GET_SALES_CHANNEL_PARTNER_BOOKING_REPORT_FILTER_PROJECT:
    "getSalesChannelPartnerBookingReportFilterProject",
  GET_SALES_CHANNEL_PARTNER_CANCELLATION_REPORT_FILTER_PROJECT:
    "getSalesChannelPartnerCancellationReportFilterProject",
  GET_SALES_CHANNEL_PARTNER_PROJECT_REPORT_FILTER_CHANNEL_PARTNER:
    "getSalesChannelPartnerProjectReportFilterChannelPartner",
  // Source Sales Report (Nithish)
  GET_SALES_SOURCE_REPORT: "getSalesSourceReport",
  GET_SALES_SOURCE_PROJECT_REPORT: "getSalesSourceProjectReport",
  GET_SALES_SOURCE_ENQUIRY_REPORT: "getSalesSourceEnquiryReport",
  GET_SALES_SOURCE_ENQUIRY_REPORT_FILTER_SOURCE:
    "getSalesSourceEnquiryReportFilterSource",
  GET_SALES_SOURCE_SITE_VISIT_REPORT_FILTER_SOURCE:
    "getSalesSourceSiteVisitReportFilterSource",
  GET_SALES_SOURCE_BOOKING_REPORT_FILTER_SOURCE:
    "getSalesSourceBookingReportFilterSource",
  GET_SALES_SOURCE_CANCELLATION_REPORT_FILTER_SOURCE:
    "getSalesSourceCancellationReportFilterSource",
  GET_SALES_SOURCE_SITE_VISIT_REPORT: "getSalesSourceSiteVisitReport",
  GET_SALES_SOURCE_BOOKING_REPORT: "getSalesSourceBookingReport",
  GET_SALES_SOURCE_CANCELLATION_REPORT: "getSalesSourceCancellationReport",
  GET_SALES_SOURCE_PROJECT_REPORT_FILTER_PROJECT:
    "getSalesSourceProjectReportFilterProject",
  GET_SALES_SOURCE_ENQUIRY_REPORT_FILTER_PROJECT:
    "getSalesSourceEnquiryReportFilterProject",
  GET_SALES_SOURCE_SITE_VISIT_REPORT_FILTER_PROJECT:
    "getSalesSourceSiteVisitReportFilterProject",
  GET_SALES_SOURCE_BOOKING_REPORT_FILTER_PROJECT:
    "getSalesSourceBookingReportFilterProject",
  GET_SALES_SOURCE_CANCELLATION_REPORT_FILTER_PROJECT:
    "getSalesSourceCancellationReportFilterProject",
  GET_ENQUIRY_SOURCE_CATEGORY: "getEnquirySourceCategory",
  GET_ENQUIRY_SOURCE_FILTER: "getEnquirySourceFilter",
  GET_SALES_CHANNEL_PARTNER_ON_BOARD_REPORT:
    "getSalesChannelPartnerOnBoardReport",
  GET_SALES_CHANNEL_PARTNER_REPORT_FILTER_SALES_EXECUTIVE:
    "getSalesChannelPartnerReportFilterSalesExecutive",
  GET_PRE_SALES_REPORT: "getPreSalesReport",
  GET_PRE_SALES_REPORT_FILTER_EXECUTIVE: "getPreSalesReportFilterExecutive",
  GET_PRE_SALES_REPORT_FILTER_SOURCE_CATEGORY:
    "getPreSalesReportFilterSourceCategory",
  GET_PRE_SALES_REPORT_FILTER_SOURCE: "getPreSalesReportFilterSource",
  GET_PRE_SALES_REPORT_FILTER_PROJECT: "getPreSalesReportFilterProject",
  GET_PRE_SALES_ENQUIRY_REPORT: "getPreSalesEnquiryReport",
  GET_PRE_SALES_PROJECT_REPORT: "getPreSalesProjectReport",
  GET_PRE_SALES_ENQUIRY_OVER_DUE_REPORT: "getPreSalesEnquiryOverdueReport"
};
/* -------------------------------------------------------------------------- */
/*                                ErpApi Starts                               */
/* -------------------------------------------------------------------------- */
export const ErpApi = {
  PROJECT_AUTHORITY: "/getProjectAuthority",
  BD_USER: "/getUser",
  PROJECT_PHASE: "/getProjectPhase",
  DISTRICTS: "/getDistrict",
  TALUKS: "/getTaluk",
  HOBLI: "/getHobli",
  PROJECT_BROKERS: "/getLandBroker",
  POST_PROJECT: "/createProject",
  POST_PROJECT_FILE: "/createProjectFile",
  PROJECT_LIST: "/getProject",
  UPCOMING_PROJECT_LIST: "/getProjectUpcoming",
  VILLAGE_LIST: "/getVillage",
  PROJECT_TASK_PLAN: "/getProjectTaskPlan",
  PROJECT_PLAN: "/getProjectPlan",
  PROJECT_OWNERS_NAMES: "getProjectTaskPlanFilterOwner",
  PROJECT_NAMES: "getProjectTaskPlanFilterProject",
  PROJECT_NAMES1: "getProjectTaskPlanFilterProject1",
  PROJECT_TASK_STATUS: "getProjectTaskStatus",
  UPDATE_PROJECT_TASK_REMARK: "updateProjectTaskRemark",
  UPDATE_PROJECT_TASK_STATUS: "uploadProjectTaskStatus",
  //UPDATE_PROJECT_TASK_APROVAL_STATUS: "uploadProjectTaskApprovalStatus",
  UPDATE_PROJECT_TASK_APROVAL_STATUS: "updateProjectTaskPlanApprovalStatus",
  // UPDATE_PROJECT_UPLOAD_DOCUMENT: "/uploadProjectTaskDocument",
  UPDATE_PROJECT_UPLOAD_DOCUMENTS: "/uploadProjectTaskDocuments", // For Multiple Document Upload
  UPDATE_PROJECT_TASK_COMPLETE: "/updateProjectTaskComplete",
  // UPDATE_PROJECT_SUB_TASK_COMPLETE: "/updateProjectTaskPlanComplete",
  UPDATE_PROJECT_SUB_TASK_COMPLETE_STATUS:
    "/updateProjectTaskPlanCompleteStatus", // with Multiple Docs
  PROJECT_DELAY_REASON: "getProjectDelayReason",
  PROJECT_DOCUMENT_TYPES: "getDocumentType",
  PROJECT_TASK_PLAN_DETAILS: "getProjectTaskPlanDetail",
  PROJECT_PROJECT_FILE: "getProjectFile",
  PROJECT_LAND_OWNER: "getLandOwner",
  //LIASON_PROCESS_PLAN: "getLiaisonProcessPlan",
  //process plan (Abhishek)
  LIASON_PROCESS_PLAN: "getLiaisonProcessPlanPending",
  LIASON_TASK_PLAN: "getLiaisonTaskPlan",
  PROJECT_BD_OWNER: "getProjectBdOwner",
  PROJECT_LEGAL_OWNER: "getProjectLegalOwner",
  LIASON_DOCUMENT_TASK_PLAN: "getLiaisonDocumentationTaskPlan",
  PROJECT_SURVEY_NO_TASK_APPROVAL: "getProjectTaskPlanApproval",
  PROJECT_SURVEY_NO_TASK_APPROVAL_DETAILS: "getProjectTaskPlanApprovalDetail",
  UPDATE_LIASON_PROCESS_APPLICATION_STATUS:
    "updateLiaisonProcessApplicabilityStatus",
  UPDATE_LIASON_TASK_PLAN_COMPLETE: "updateLiaisonTaskPlanComplete",
  MY_WEB_NOTIFICATION: "getMyWebNotification",
  AUTHORIZATION: "getFunctionalityPermission",
  MENU_PERMISSION: "getMenu",
  FUNCTIONALITY: "getFunctionality",
  UPDATE_USER_FUNCTIONALITY_PERMISSION: "/updateUserFunctionalityPermission",
  UPDATE_FIREBASE_KEY: "updateFirebaseKeyWeb",
  PROJECT_PLAN_FILE_PLAN: "/getProjectPlanFile",
  LIAISON_PROCESS_CATEGORY: "/getLiaisonProcessCategory",
  FILE_LIAISON_PROCESS_PLAN: "/getFileLiaisonProcessPlan",
  UPDATE_LIAISON_PROCESS_PLAN_APPLICABILITY:
    "/updateLiaisonProcessPlanApplicabilityStatus",
  GET_PROJECT_TASK_PLAN_MANDOTORY_DOCUMENT:
    "/getProjectTaskPlanMandatoryDocument",
  UPLOAD_PROJECT_TASK_PLAN_MANDOTORY_DOCUMENT:
    "/uploadProjectTaskPlanMandatoryDocument",
  //New
  GET_SURVEY_NO: "getProjectPlanFile",
  GET_SURVEY_NO_DETAILS: "getProjectFileDetail",
  CREATE_FILE_ACQUISITION_INFO: "createFileAcquisitionInfo",
  GET_FILE_ACQUISITION_INFO: "getFileAcquisitionInfo",
  GET_FILE_ACQUISITION_MODE: "getFileAcquisitionMode",
  GET_FILE_ACQUISITION_INFO_DETAIL: "getFileAcquisitionInfoDetail",
  UPDATE_FILE_ACQUISITION_INFO_STATUS: "updateFileAcquisitionInfoStatus",
  GET_PROJECT_WITH_EXTENT: "getProjectWithExtent",
  GET_FEASIBILITY_ITEM: "getProjectFeasibilityItem",
  UPDATE_FEASIBILITY_REPORT: "updateFeasibilityReport",
  GET_FEASIBILITY_REPORT: "getFeasibilityReport",
  GET_FEASIBILITY_REPORT_DETAIL: "getFeasibilityReportDetail",
  CREATE_LAND_OWNER: "createLandOwner",
  CREATE_LAND_OWNER_ACCOUNT: "createLandOwnerAccount",
  GET_LAND_OWNER_DETAIL: "getLandOwnerDetail",
  GET_LAND_BROKER: "getProjectBdLandBroker",
  CREATE_LAND_BROKER: "createLandBroker",
  GET_LAND_BROKER_DETAIL: "getProjectBdLandBrokerDetail",
  CREATE_LAND_BROKER_ACCOUNT: "createLandBrokerAccount",
  FEASIBILITY_REPORT_SEND_FOR_APPROVAL: "feasibilityReportSendForApproval",
  APPROVE_FEASIBILITY_REPORT_TASK_PLAN: "approveFeasibilityReportTaskPlan",
  GET_LAND_OWNER_VILLAGE: "getLandOwnerVillage",
  GET_LIAISON_DOCUMENT_CATEGORY: "getLiaisonDocumentCategory",
  UPDATE_LIAISON_DOCUMENT_CATEGORY: "updateLiaisonDocumentCategory",
  UPDATE_LIAISON_TASK_PLAN_OWNER: "updateLiaisonTaskPlanOwner",
  UPDATE_LIAISON_TASK_PLAN_STATUS: "updateLiaisonTaskPlanStatus",
  UPDATE_BD_PROJECT_TASK_PLAN_OWNER: "updateBdProjectTaskPlanOwner",
  UPDATE_LIAISON_PROCESS_PLAN_OWNER: "updateLiaisonProcessPlanOwner",
  UPDATE_LIAISON_PROCESS_PLAN_REMARK: "updateLiaisonProcessPlanRemark",
  CREATE_LIAISON_DOC_CHECKLIST_PLAN: "createLiaisonDocumentCheckListPlan",
  IMPORT_LIAISON_DOC_CHECKLIST_PLAN: "importLiaisonDocumentCheckListPlan",
  GET_PROJECT_FUND_SOURCE: "getProjectFundSource",
  GET_PROJECT_FUND_REQUIREMENT: "getProjectFundRequirement",
  UPDATE_PROJECT_FUND_REQUIREMENT: "updateProjectFundRequirement",
  GET_PROJECT_FUND: "getProjectFund",
  GET_PROJECT_FUND_DETAIL: "getProjectFundDetail",
  GET_PROJECT_FILE_PAYMENT_DETAIL: "getProjectFilePaymentDetail",
  ADD_LAND_OWNER_PAYMENT: "addLandOwnerPayment",
  GET_BD_PAYMENT: "getBdPayment",
  _GET_BD_PAYMENT_DETAIL: "getBdPaymentDetail",
  UPDATE_BD_PAYMENT_STATUS: "updateBdPaymentStatus",
  GET_BD_PAYMENT_MILESTONE: "getBdPaymentMilestone",
  GET_PROJECT_BROKER: "getProjectBroker",
  GET_PROJECT_BROKER_DETAIL: "getProjectBrokerDetail",
  UPDATE_PROJECT_BROKER_STATUS: "updateProjectBrokerStatus",
  ADD_PROJECT_BROKER_CHARGE: "addProjectBrokerCharge",
  UPDATE_PROJECT_BROKER_CHARGE_STATUS: "updateProjectBrokerChargeStatus",
  ADD_PROJECT_BROKER_PAYMENT: "addProjectBrokerPayment",
  ADD_PROJECT_BROKER_PAYMENT_INFO: "getProjectBrokerPaymentInfo",
  GET_PROJECT_LAND_OWNER: "getProjectLandOwner",
  GET_PROJECT_OWNER_SURVEY_NUMBER: "getProjectOwnerSurveyNo",
  GET_PROJECT_FILE_ADVANCE_PAYMENT_DETAIL: "getProjectFileAdvancePaymentDetail",
  GET_LIAISON_PROCESS_PLAN_FILE: "getLiaisonProcessPlanFile",
  ADD_LIAISON_PROCESS_PLAN_START: "liaisonProcessPlanStart",
  GET_LIAISON_PROCESS_PLAN_APPLICATION: "getLiaisonProcessPlanApplication",
  UPDATE_LIAISON_PROCESS_PLAN_APPLICATION_OWNER:
    "updateLiaisonProcessPlanApplicationOwner",
  GET_LIAISON_PROCESS_PLAN_APPLICATION_FILE_DETAIL:
    "getLiaisonProcessPlanApplicationFileDetail",
  ADD_LIAISON_PROCESS_PLAN_SPLIT: "liaisonProcessPlanSplit",
  UPDATE_LIAISON_PROCESS_PLAN_APPLICATION_STATUS:
    "updateLiaisonProcessPlanApplicationStatus",
  GET_PROJECTC_MILESTONE: "getProjectMilestone",
  GET_BD_REGISTRATION_ITEM: "getBdRegistrationItem",
  GET_SUBREGISTER_OFFICE: "getSubRegisterOffice",
  ADD_LAND_GOVT_PAYMENT: "addLandRegistrationPayment",
  UPDATE_SURVEY_NUMBER_CREATION: "updateSurveyNumberCreation",
  UPLOAD_PROJECT_FILE_DOCUMENT: "uploadProjectFileDocument",
  GET_PROJECT_FILE_DOCUMENT: "getProjectFileDocument",
  UPDATE_PROJECT_FILE: "updateProjectFile",
  UPDATE_PROJECT_FILE_STATUS: "updateProjectFileStatus",
  GET_DEPENDENT_RELATIONSHIP: "getDependentRelationship",
  UPDATE_LAND_OWNER: "updateLandOwner",
  UPDATE_LAND_OWNER_STATUS: "updateLandOwnerStatus",
  UPDATE_LAND_BROKER: "updateLandBroker",
  UPDATE_LAND_BROKER_STATUS: "updateLandBrokerStatus",
  _GET_BD_PAYMENT_ADVANCE_DETAIL: "getBdPaymentAdvanceDetail",
  BD_LAND_PAYMENT_SETTLE: "bdLandPaymentSettle",
  GET_ACCOUNT_PAYMENT: "getAccountPayment",
  GET_COMPANY: "getCompany",
  GET_PAYMENT_CATEGORY: "getPaymentCategory",
  UPDATE_PAYMENT_COMPANY: "updatePaymentCompany",
  GET_ACCOUNT_PAYMENT_DETAIL: "getAccountPaymentDetail",
  GET_COMPANY_BANK_ACCOUNT: "getCompanyBankAccount",
  GET_PAYMENT_MODE_CATEGORY: "getPaymentModeCategory",
  GET_PAYMENT_MODE: "getPaymentMode",
  PAY_ACCOUNT_PAYMENT: "payAccountPayment",
  GET_ACCOUNT_PAYMENT_PAID: "getAccountPaymentPaid",
  GET_ACCOUNT_PAYMENT_PAID_DETAIL: "getAccountPaymentPaidDetail",
  GET_PROJECT_DETAIL: "getProjectDetail",
  GET_PROJECT_DROP_REASON: "getProjectDropReason",
  UPDATE_PROJECT_STATUS: "updateProjectStatus",
  UPDATE_PROJECT_BD_OWNER: "updateProjectBdOwner",
  UPDATE_PROJECT_LEGAL_OWNER: "updateProjectLegalOwner",
  UPDATE_PROJECT_DETAIL: "updateProjectDetail",
  COMPANY_PROJECT_MAP: "getCompanyProjectMap",
  COMPANY_PROJECT_MAP_LIST: "getCompanyProjectMapList",
  ADD_PROJECT_COMPANY_MAP: "addProjectCompanyMap",
  UPDATE_PROJECT_COMPANY_MAP: "updateProjectCompanyMap",
  GET_DROPPED_PROJECT_LIST: "/getDroppedProject",
  GET_LAND_OWNER_ACCOUNT_DETAIL: "getLandOwnerAccountDetail",
  UPDATE_LAND_OWNER_ACCOUNT_DETAIL: "updateLandOwnerAccount",
  GET_LAND_BROKER_ACCOUNT_DETAIL: "getLandBrokerAccountDetail",
  UPDATE_LAND_BROKER_ACCOUNT_DETAIL: "updateLandBrokerAccount",
  GET_LIAISON_PROCESS_PLAN_APPLICATION_FILTER_OWNER:
    "getLiaisonProcessPlanApplicationFilterOwner",
  GET_LIAISON_DOC_TASK_PLAN_FILTER_OWNER:
    "getLiaisonDocumentationTaskPlanFilterOwner",
  USER_PROFILE: "getUserProfile",
  UPDATE_EMPLOYEE_PROFILE: "updateEmployeeProfile",
  UPDATE_USER_PROFILE_IMAGE: "updateUserProfileImage",
  GET_BIRTHDAY: "getEmployeeBirthday",
  UPDATE_USER_GREETING_IMAGE: "updateUserGreetingImage",
  GET_WORK_ANNIVERSARY: "getEmployeeWorkAnniversary",
  UPDATE_USER_ANNIVERSARY_GREETING_IMAGE: "updateUserAnniversaryGreetingImage",
  GET_PROJECT_FILTER: "getProjectFilter",
  GET_PROJECT_UPCOMING_FILTER: "getProjectUpcomingFilter",
  GET_CIVIL_PROJECT_FILTER: "getCivilProjectFilter",
  GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL:
    "getLiaisonDocumentationTaskPlanApproval",
  GET_PROJECT_FILE_FILTER: "getProjectFileFilter",
  GET_LIAISON_PROCESS_FILTER: "getLiaisonProcessFilter",
  GET_APF_PANEL: "getApfPanel",
  GET_APF_PENDING_PROJECT_FILE: "getApfPendingProjectFile",
  CREATE_APF_TASK_PLAN: "createApfTaskPlan",
  GET_APF_TASK_PLAN: "getApfTaskPlan",
  GET_APF_TASK_PLAN_DETAIL: "getApfTaskPlanDetail",
  UPDATE_APF_TASK_REMARK: "updateApfTaskRemark",
  UPDATE_APF_TASK_PLAN_OWNER: "updateApfTaskPlanOwner",
  UPLOAD_APF_TASK_DOCUMENT: "uploadApfTaskDocument",
  UPDATE_APF_TASK_PLAN_START: "updateApfTaskPlanStart",
  UPDATE_APF_TASK_PLAN_COMPLETE: "updateApfTaskPlanComplete",
  GET_APF_TASK_PLAN_PROJECT_FILTER: "getApfTaskPlanProjectFilter",
  // GET_APF_TASK_PLAN_OWNER_FILTER:"getApfTaskPlanOwnerFilter",
  GET_APF_TASK_PLAN_PANEL_FILTER: "getApfTaskPlanPanelFilter",
  GET_APF_TASK_PLAN_PROJECT_FILE_FILTER: "getApfTaskPlanProjectFileFilter",
  GET_APF_TASK_PLAN_TASK_FILTER: "getApfTaskPlanTaskFilter",
  GET_APF_TASK_FILTER: "getApfTask",
  GET_APF_TASK_PLAN_QUERY: "getApfTaskPlanQuery",
  CREATE_APF_TASK_PLAN_QUERY: "createApfTaskPlanQuery",
  UPLOAD_APF_TASK_QUERY_DOCUMENT: "uploadApfTaskQueryDocument",
  GET_APF_TASK_PLAN_QUERY_DETAIL: "getApfTaskPlanQueryDetail",
  UPDATE_APF_TASK_PLAN_QUERY_OWNER: "updateApfTaskPlanQueryOwner",
  UPDATE_APF_TASK_PLAN_QUERY_STATUS: "updateApfTaskPlanQueryStatus",
  UPDATE_APF_TASK_PLAN_QUERY_STATUS_ALL: "updateApfTaskPlanQueryStatusAll",
  GET_LIAISON_DOCUMENTATION_REPORT: "getLiaisonDocumentationReport",
  GET_BD_LAND_DISPUTE_TYPE: "getBdLandDisputeType",
  CREATE_BD_LAND_DISPUTE: "createBdLandDispute",
  GET_BD_LAND_DISPUTE: "getBdLandDispute",
  UPLOAD_BD_LAND_DISPUTE_DOCUMENT: "uploadBdLandDisputDocument",
  GET_BD_LAND_DISPUTE_DETAIL: "getBdLandDisputeDetail",
  UPDATE_LAND_DISPUTE_OWNER: "updateBdLandDisputOwner",
  UPDATE_LAND_DISPUTE_REMARK: "updateBdLandDisputRemark",
  UPDATE_BD_LAND_DISPUTE_STATUS: "updateBdLandDisputStatus",
  GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILTER:
    "getLiaisonDocumentationReportProjectFilter",
  GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILE_FILTER:
    "getLiaisonDocumentationReportProjectFileFilter",
  GET_LIAISON_DOCUMENTATION_REPORT_CATEGORY_FILTER:
    "getLiaisonDocumentationReportCategoryFilter",
  GET_LIAISON_DOCUMENTATION_REPORT_OWNER_FILTER:
    "getLiaisonDocumentationReportOwnerFilter",
  GET_COURT_CASE_TYPE: "getCourtCaseType",
  GET_COURT_CASE_STATUS: "getCourtCaseStatus",
  GET_COURT_CASE_JURISDICTION: "getCourtCaseJurisdiction",
  GET_DIRECTOR: "getDirector",
  CREATE_COURT_CASE: "createCourtCase",
  GET_COURT_CASE: "getCourtCase",
  UPLOAD_COURT_CASE_DOCUMENT: "uploadCourtCaseDocument",
  UPDATE_COURT_CASE_OWNER: "updateCourtCaseOwner",
  UPDATE_COURT_CASE_REMARK: "updateCourtCaseRemark",
  UPDATE_COURT_CASE_STATUS: "updateCourtCaseStatus",
  GET_COURT_CASE_DETAIL: "getCourtCaseDetail",
  UPDATE_COURT_CASE: "updateCourtCase",
  UPDATE_BD_PROJECT_TASK_PLAN_START: "updateBdProjectTaskPlanStart",
  CREATE_TRAINING_CONTENT: "createTrainingContent",
  GET_TRAINING_CONTENT: "getTrainingContent",
  UPDATE_TRAINING_CONTENT: "updateTrainingContent",
  DELETE_PROJECT_FILE_DOCUMENT: "deleteProjectFileDocument",
  GET_BD_PROJECT_TASK_REJECTION_REASON: "getBdProjectTaskRejectionReason",
  GET_BD_AGREEMENT_TYPE: "getBdAgreementType",
  GET_BD_PROJECT_REPORT: "getBdProjectReport",
  GET_BD_PROJECT_TASK_REPORT: "getBdProjectTaskReport",
  CREATE_PROJECT_PROPOSED_NAME: "createProjectProposedName",
  GET_PROJECT_PROPOSED_NAME: "getProjectProposedName",
  DELETE_PROJECT_PROPOSED_NAME: "deleteProjectProposedName",
  UPDATE_PROJECT_PROPOSED_NAME_STATUS: "updateProjectProposedNameStatus",
  DELETE_TRANING_CONTENT: "deleteTrainingContent",
  GET_TRAINING: "getMyTrainingContent",
  GET_LIAISON_PROCESS_PLAN_PROJECT: "getLiaisonProcessPlanProject",
  GET_LIAISON_PROCESS_PLAN_PROCESS: "getLiaisonProcessPlanProcess",
  CREATE_LIAISON_PAYMENT: "createLiaisonPayment",
  GET_LIAISON_PAYMENT_LIST: "getLiaisonPayment",
  GET_LIAISON_PAYMENT_DETAIL: "getLiaisonPaymentDetail",
  DELETE_LIAISON_PAYMENT: "deleteLiaisonPayment",
  GET_LIAISON_PAYMENT_SURVEY_NUMBER: "getLiaisonPaymentSurveyNumber",
  GET_LIAISON_PAYMENT_TASK_PLAN: "getLiaisonPaymentTaskPlan",
  ADD_LIAISON_PAYMENT_ITEM: "addLiaisonPaymentItem",
  DELETE_LIAISON_PAYMENT_ITEM: "deleteLiaisonPaymentItem",
  UPDATE_LIAISON_PAYMENT_STATUS: "updateLiaisonPaymentStatus",
  UPDATE_LIAISON_PAYMENT_ISSUE: "updateLiaisonPaymentIssue",
  UPDATE_LIAISON_PAYMENT_ITEM_ACTUAL_AMOUNT:
    "updateLiaisonPaymentItemActualAmount",
  UPDATE_LIAISON_PAYMENT_SETTLEMENT_APPROVAL:
    "updateLiaisonPaymentSettlementApproval",
  UPDATE_LIAISON_PAYMENT_SETTLEMENT_SETTLE:
    "updateLiaisonPaymentSettlementSettle",
  GET_FUNCTIONALITY_PERMISSION_DETAIL: "getFunctionalityPermissionDetail",
  GET_LIAISON_PAYMENT_FILTER_PROJECT: "getLiaisonPaymentFilterProject",
  GET_LIAISON_PAYMENT_FILTER_REQUESTED_BY: "getLiaisonPaymentFilterRequestedBy",
  GET_PROJECT_TASK_PLAN_FILTER_TASK_OWNER: "getProjectTaskPlanFilterTaskOwner",
  GET_PROJECT_TASK_PLAN_FILTER_TASK: "getProjectTaskPlanFilterTask",
  UPDATE_LIAISON_TASK_PLAN_START: "updateLiaisonTaskPlanStart",
  UPDATE_LIAISON_TASK_PLAN_OWNERS: "updateLiaisonTaskPlanOwners",
  GET_LIAISON_DOCUMENT_TASK_PLAN_PENDING:
    "getLiaisonDocumentationTaskPlanPending",
  GET_LIAISON_APPLICABLE_PROCESS_PLAN: "getLiaisonApplicableProcessPlan",
  GET_DEPARTMENT: "getDepartment",
  DELETE_PROJECT_DOCUMENT: "deleteProjectDocument",
  UPDATE_LIAISON_DOCUMENT_TASK_PLAN: "updateLiaisonDocumentTaskPlan",
  GET_LIAISON_DOCUMENT_TASK_PLAN_APPROVAL_DETAIL:
    "getLiaisonDocumentationTaskPlanApprovalDetail",
  GET_LIAISON_DOCUMENT_TASK_PLAN_DETAIL:
    "getLiaisonDocumentationTaskPlanDetail",
  UPDATE_NOTIFICATION_VIEW_STATUS: "updateNotificationViewStatus",
  UPDATE_APF_TASK_PLAN_QUERY: "updateApfTaskPlanQuery",
  GET_PROJECT_TASK_PLAN_FILTER_TASK_CATEGORY:
    "getProjectTaskPlanFilterTaskCategory",
  GET_LIAISON_PROCESS_PLAN_APPLICATION_DETAIL_1:
    "getLiaisonProcessPlanApplicationDetail1",
  GET_LIAISON_TASK_PLAN_DETAIL: "getLiaisonTaskPlanDetail",
  UPDATE_LIAISON_TASK_PLAN_APPLICABILITY_STATUS:
    "updateLiaisonTaskPlanApplicabilityStatus",
  GET_LIAISON_PROCESS_PLAN_PENDING_DETAIL: "getLiaisonProcessPlanPendingDetail",
  GET_LIAISON_TASK: "getLiaisonTask",
  GET_LIAISON_PROCESS_PLAN_PENDING_TASK: "getLiaisonProcessPlanPendingTask",
  ADD_LIAISON_PROCESS_PLAN_TASK: "addLiaisonProcessPlanTask",
  GET_LIAISON_PROCESS_OPTIONAL: "getLiaisonProcessOptional",
  GET_LIAISON_PROCESS_SURVEY_NUMBER_OPTIONAL:
    "getLiaisonProcessSurveyNumberOptional",
  ADD_LIAISON_PROCESS_PLAN_EXTRA: "addLiaisonProcessPlanExtra",
  GET_LIAISON_PROCESS_PLAN: "getLiaisonProcessPlan",
  GET_LIAISON_PROCESS_PLAN_APPROVAL: "getLiaisonProcessPlanApproval",
  GET_LIAISON_PROCESS_PLAN_DETAIL: "getLiaisonProcessPlanDetail",
  GET_LIAISON_PROCESS_PLAN_FILTER_PROJECT: "getLiaisonProcessPlanFilterProject",
  GET_LIAISON_PROCESS_PLAN_FILTER_PROCESS: "getLiaisonProcessPlanFilterProcess",
  GET_LIAISON_PROCESS_PLAN_FILTER_OWNER: "getLiaisonProcessPlanFilterOwner",
  UPDATE_LIAISON_PROCESS_PLAN_STATUS: "updateLiaisonProcessPlanStatus",
  UPLOAD_NEWS_LETTER: "uploadNewsLetter",
  GET_NEWS_LETTER: "getNewsLetter",
  SEND_NEWS_LETTER_WHATSAPP: "sendNewsLetterWhatsApp",
  SEND_NEWS_LETTER_EMAIL: "sendNewsLetterEmail",
  GET_LIAISON_PROCESS_IDENTIFICATION_PENDING_FILE:
    "getLiaisonProcessIdentificationPendingFile",
  GET_LIAISON_PROCESS_IDENTIFICATION_PENDING_CATEGORY:
    "getLiaisonProcessIdentificationPendingCategory",
  UPDATE_LIAISON_PROCESS_APPLICABILITY_STATUS_REMOVE:
    "updateLiaisonProcessApplicabilityStatusRemove",
  GET_PROJECT_ZONE: "getProjectZone",
  UPDATE_LIAISON_TASK_PLAN_REJECT: "updateLiaisonTaskPlanReject",
  //GET_COURT_CASE_HANDLED_BY: "getCourtCaseHandledByFilter",
  GET_COURT_CASE_HANDLED_BY: "getCourtCaseHandledByFilter",
  GET_BD_LAND_DISPUTE_HANDLED_BY: "getBdLandDisputeHandledByFilter",
  DELETE_BD_LAND_DISPUT: "deleteBdLandDisput",
  UPDATE_COURT_CASE_ACTIVE: "updateCourtCaseActive",
  DELETE_COURT_CASE: "deleteCourtCase",
  GET_COURT_CASE_PROJECT_FILTER: "getCourtCaseProjectFilter",
  CREATE_PROJECT_DEED: "createProjectDeed",
  GET_PROJECT_DEED: "getProjectDeed",
  CREATE_EXTRA_LIAISON_PROCESS_PLAN: "createExtraLiaisonProcessPlan",
  CREATE_PROJECT_TASK_SUPPLEMENTARY_PLAN: "createProjectTaskSupplementaryPlan",
  GET_BD_PROJECT_CONSOLIDATED_REPORT: "getBdProjectConsolidatedReport",
  GET_PROJECT_FILE_ACQUISITION_STATUS_FILTER:
    "getProjectFileAcquisitionStatusFilter",
  GET_BD_PROJECT_CONSOLIDATED_REPORT_PRINT:
    "getBdProjectConsolidatedReportPrint",
  GET_BD_FILE_ACQUISITION_REASON: "getbdFileAcquisitionReason",
  GET_PROJECT_LEGAL_OWNER_FILTER: "getProjectLegalOwnerFilter",
  GET_PROJECT_BD_OWNER_FILTER: "getProjectBdOwnerFilter",
  UPDATE_LIAISON_PROCESS_PLAN_OWNERS: "updateLiaisonProcessPlanOwners",
  GET_LIAISON_PROCESS_PLAN_APPLICABILITY_REASON:
    "getLiaisonProcessPlanApplicabilityReason",
  GET_LOAN_PANEL: "getLoanPanel",
  GET_LOAN_APPLICATION_ELIGIBLE_PROJECT: "getLoanApplicationEligibleProject",
  GET_LOAN_APPLICATION_ELIGIBLE_PROJECT_FILE:
    "getLoanApplicationEligibleProjectFile",
  CREATE_LOAN_PROCESS_PLAN: "createLoanProcessPlan",
  GET_LOAN_PROCESS_PLAN: "getLoanProcessPlan",
  GET_LOAN_TASK_PLAN: "getLoanTaskPlan",
  UPDATE_LOAN_TASK_PLAN_START: "updateLoanTaskPlanStart",
  UPDATE_LOAN_PLAN_COMPLETE: "updateLoanTaskPlanComplete",
  GET_LOAN_TASK_PLAN_DETAIL: "getLoanTaskPlanDetail",
  UPDATE_PROJECT_LIAISON_OWNER: "updateProjectLaisonOwner",
  GET_PROJECT_LIAISON_OWNER_FILTER: "getProjectLiaisonOwnerFilter",
  UPDATE_LOAN_TASK_PLAN_OWNER: "updateLoanTaskPlanOwner",
  UPDATE_LOAN_PROCESS_APPLICABILITY_STATUS:
    "updateLoanProcessApplicabilityStatus",
  UPDATE_LOAN_TASK_PLAN_APPLICABILITY_STATUS:
    "updateLoanTaskPlanApplicabilityStatus",
  UPDATE_LOAN_PROCESS_PLAN_OWNER: "updateLoanProcessPlanOwner",
  GET_LOAN_PROCESS_PLAN_DETAIL: "getLoanProcessPlanDetail",
  GET_LOAN_PROCESS_PLAN_PROJECT_FILTER: "getLoanProcessPlanProjectFilter",
  GET_LOAN_PROCESS_PLAN_PROCESS_FILTER: "getLoanProcessPlanProcessFilter",
  GET_LOAN_PROCESS_PLAN_BANK_FILTER: "getLoanProcessPlanBankFilter",
  GET_LOAN_PROCESS_PLAN_OWNER_FILTER: "getLoanProcessPlanOwnerFilter",
  GET_LIAISON_PROCESS_PLAN_DELAYED: "getLiaisonProcessPlanDelayed",
  SPLIT_PROJECT_FILE: "splitProjectFile",
  PROJECT_MERGER: "projectMerger",
  GET_MASTER: "getMaster",
  GET_PROJECT_FILE_ACQUISITION_PENDING: "getProjectFileAcquisitionPending",
  UPDATE_PROJECT_FILE_ACQUISTION_PENDING_REMARK:
    "updateProjectFileAcquisitionPendingRemark",
  UPDATE_PROJECT_FILE_ACQUISTION_OWNER: "updateProjectFileAcquisitionOwner",
  GET_PROJECT_FILE_ACQUISTION_PENDING_DETAIL:
    "getProjectFileAcquisitionPendingDetail",
  GET_PROJECT_FILE_ACQUISTION_HANDLED_BY_FILTER:
    "getProjectFileAcquisitionHandledByFilter",
  GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT: "getLiaisonPlanApprovalReport",
  GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT_FILTER_PROJECT:
    "getLiaisonPlanApprovalReportFilterProject",
  GET_PROJECT_MOU_REPORT_KPI: "getProjectMoUReportKPI",
  GET_PROJECT_MOU_KPI_REPORT_FILTER_PROJECT:
    "getProjectMoUKPIReportFilterProject",
  GET_PROJECT_BD_KPI_REPORT: "getProjectBdKPIReport",
  GET_PROJECT_BD_KPI_REPORT_FILTER_PROJECT:
    "getProjectBdKPIReportFilterProject",

  // Liaison process (Abhishek)
  GET_LIAISON_PROCESS: "getLiaisonProcess",
  GET_LIAISON_PROCESS_CATEGORY1: "getLiaisonProcessCategory1",
  CREATE_LIAISON_PROCESS: "createLiaisonProcess",
  UPDATE_LIAISON_PROCESS_OWNER: "updateLiaisonProcessOwner",
  GET_BD_PROJECT_TASK: "getBdProjectTask",

  // ADDED BY NIHTISH
  CREATE_LAND_DISPUTE: "createLandIssueType",
  UPDATE_LAND_DISPUTE: "updateLandIssueType",
  GET_LIAISON_PROCESS_CATEGORY: "getLiaisonProcessCategoryMaster",
  CREATE_LIAISON_PROCESS_CATEGORY: "createLiaisonProcessCategory",
  UPDATE_LIAISON_PROCESS_CATEGORY: "updateLiaisonProcessCategory",
  GET_BD_PROJECT_TASK_CATEGORY: "getBdProjectTaskCategory",
  UPDATE_BD_PROJECT_TASK: "updateBdProjectTaskOwner",
  CREATE_BD_PROJECT_TASK: "createBdProjectTask",
  UPDATE_BD_PROJECT_TASK_PLANE: "updateBdProjectTask",
  CREATE_PROJECT_TASK_CATEGORY: "createBdProjectTaskCategory",
  UPDATE_PROJECT_TASK_CATEGORY: "updateBdProjectTaskCategory",
  PROJECT_TASK_DEPENDENCY: "getBdProjectTaskDependency",
  CREATE_TASK_DEPENDENCY: "createBdProjectTaskDependency",
  PROJECT_DEPENDENT_TASK: "getBdProjectDependentTask",
  UPDATE_TASK_DEPENDENCY: "updateBdProjectTaskDependency",
  GET_PLANNING_AUTHORITY: "getPlanningAuthority",
  CREATE_PLANNING_AUTHORITY: "createPlanningAuthority",
  UPDATE_PLANNING_AUTHORITY: "updatePlanningAuthority",
  GET_LIAISON_CATEGORY: "getLiaisonTaskCategory",
  CREATE_LIAISON_TASK_CATEGORY: "createLiaisonTaskCategory",
  UPDATE_LIAISON_CATEGORY: "updateLiaisonTaskCategory",
  UPDATE_LIAISON_TASK: "updateLiaisonTask",
  CREATE_LIAISON_TASK: "createLiaisonTask",
  GET_LIAISON_PROCESS_DEPENDENCY: "getLiaisonProcessDependency",
  CREATE_LIAISON_PROCESS_DEPENDENCY: "createLiaisonProcessDependency",
  UPDATE_LIAISON_PROCESS_DEPENDENCY: "updateLiaisonProcessDependency",
  GET_LIAISON_PROCESS_MASTER: "getLiaisonProcess",
  GET_LIAISON_PROCESS_CATEGORY_MASTER_NEW: "getLiaisonProcessCategory1",
  GET_LIAISON_PROCESS_DETAIL: "getLiaisonProcessDetail",
  CREATE_LIAISON_PROCESS_MASTER: "createLiaisonProcess",
  UPDATE_LIAISON_PROCESS_MASTER: "updateLiaisonProcess",
  GET_LIAISON_PROCESS_PLAN_FILTER_SY_NUMBER:
    "getLiaisonProcessPlanFilterSyNumber",
  GET_LIAISON_DOC_TASK_PLAN_FILTER_ACCOUNTABLE:
    "getLiaisonDocumentationTaskPlanFilterAccountable",
  UPDATE_LIAISON_DOCUMENTATION_TASK_PLAN_REJECT:
    "updateLiaisonDocumentationTaskPlanReject",

  // HR Modules
  ADD_TASK: "createTask",
  GET_TASK: "getTask",
  GET_TASK_CATEGORY: "getTaskCategory",
  UPDATE_TASK_OWNER: "updateTaskOwner",
  UPDATE_TASK_START: "updateTaskStart",
  UPDATE_TASK_COMPLETED: "updateTaskComplete",
  GET_TASK_DETAIL: "getTaskDetail",
  GET_TASK_PROJECT_FILTER: "getTaskProjectFilter",
  GET_TASK_CATEGORY_FILTER: "getTaskCategoryFilter",
  GET_TASK_DEPARTMENT_FILTER: "getTaskDepartmentFilter",
  GET_TASK_OWNER_FILTER: "getTaskOwnerFilter",

  // CIVIL MODULES (Sadanand)
  GET_CIVIL_PROJECT: "getCivilProject",
  GET_CIVIL_PROJECT_PLANNIG_STAGE: "getCivilProjectPlanningStage",
  CREATE_CIVIL_PROJECT_PLAN: "createCivilProjectPlan",
  GET_CIVIL_PROJECT_TEAM_ROLE: "getCivilProjectTeamRole",
  GET_CIVIL_PROJECT_PLAN_LIST: "getCivilProjectPlanList",
  CREATE_CIVIL_PROJECT_TEAM: "createCivilProjectTeam",
  CREATE_CIVIL_PROJECT_LOCATION: "createCivilProjectLocation",
  GET_LIAISON_PROCESS_PLAN_FILTER_ACCOUNTABLE:
    "getLiaisonProcessPlanFilterAccountable",
  UPDATE_LIASON_PROCESS_DOCUMENT_TYPE: "updateLiaisonDocumentType",
  UPDATE_PROJECT_TASK_APPLICABLE_STATUS:
    "updateProjectTaskPlanApplicableStatus",
  GET_CIVIL_PROJECT_PLAN_TEAM: "getCivilProjectPlanTeam",
  GET_CIVIL_PROJECT_SITE_LOCATION: "getCivilProjectSiteLocation",
  GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT: "getCivilProjectPlanFilterProject",
  GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT_ID:
    "getCivilProjectPlanFilterProjectID",
  GET_CIVIL_WBS: "getCivilWbs",
  CREATE_CIVIL_PROJECT_PROCESS_PLAN: "createCivilProjectProcessPlan",
  GET_CIVIL_PROJECT_PROCESS_PLAN: "getCivilProjectProcessPlan",
  GET_CIVIL_PROJECT_PROCESS_PLAN_FILTER_PROCESS:
    "getCivilProjectProcessPlanFilterProcess",
  GET_CIVIL_PROJECT_PROCESS_TASK: "getCivilActivity",
  GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_FILTER_TASK:
    "getCivilProjectProcessTaskPlanFilterTask",
  GET_TASK_DEPENDENCY_TYPE: "getTaskDependencyType",
  CREATE_CIVIL_PROJECT_PROCESS_TASK_PLAN: "createCivilProjectTaskPlan",
  GET_CIVIL_PROJECT_PLAN_FILTER_STAGE: "getCivilProjectPlanFilterStage",
  GET_CIVIL_PROJECT_PROCESS_TASK_PLAN: "getCivilProjectProcessTaskPlan",
  GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_LOCATION_QUANTITY:
    "getCivilProjectTaskPlanLocationQuantity",
  CREATE_CIVIL_PROJECT_TASK_PLAN_QUANTITY: "createCivilProjectTaskPlanQuantity",
  GET_CIVIL_PROJECT_TASK_MATERIAL_CATEGORY: "getCivilMaterialCategory",
  GET_CIVIL_MATERIAL: "getCivilMaterial",
  CREATE_CIVIL_PROJECT_PLAN_MATERIAL: "createCivilProjectTaskPlanMaterial",
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL: "getCivilProjectTaskPlanMaterial",
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_DELETE:
    "getCivilProjectTaskPlanMaterialDelete",
  GET_CIVIL_PROJECT_ACTIVITY_MASTER: "getCivilProjectActivityMaster",

  //Schedule(Sadanand)
  GET_CIVIL_PROJECT_ACTIVITY: "getCivilProjectActivity",
  UPDATE_CIVIL_PROJECT_PLAN: "updateCivilProjectPlan",
  GET_CIVIL_PROJECT_WBS: "getCivilProjectWbs",
  GET_CIVIL_PROJECT_RESOURCE_1: "getCivilProjectResource",
  CREATE_CIVIL_PROJECT_TASK_PLAN_RESOURCE: "createCivilProjectTaskPlanResource",
  CREATE_CIVIL_PROJECT_TASK_PLAN_RESOURCE_AMEND:
    "createCivilProjectTaskPlanResourceAmendment",
  GET_WBS_ACTIVITY: "getWbsActivity",
  GET_CIVIL_RESOURCE_ALL: "getCivilResourceAll",
  GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE: "getCivilProjectTaskPlanResource",
  UPDATE_CIVIL_PROJECT_TASK_PLAN_DURATION: "updateCivilProjectTaskPlanDuration",
  GET_CIVIL_PROJECT_ACTIVITY_DETAIL: "getCivilProjectActivityDetail",
  CREATE_CIVIL_PROJECT_TASK_PLAN_DEPENDENCY:
    "createCivilProjectTaskPlanDependancy",
  GET_CIVIL_PROJECT_DEPENDABLE_ACTIVITY: "getCivilProjectDependableActivity",
  GET_CIVIL_PROJECT_ROLE: "getCivilProjectRole",
  CREATE_CIVIL_PROJECT_ROLE: "createCivilProjectRole",
  UPDATE_CIVIL_PROJECT_ROLE: "updateCivilProjectRole",
  GET_BD_PAYMENT_MILESTONE_MASTER: "getBdPaymentMilestoneList",
  CREATE_BD_PAYMENT_MILESTONE: "createBdPaymentMilestoneList",
  UPDATE_BD_PAYMENT_MILESTONE: "updateBdPaymentMilestoneList",
  GET_PROJECT_TASK_REJECTION_REASON_MASTER: "getBdProjectTaskRejectionReason",
  CREATE_PROJECT_TASK_REJECTION_REASON_MASTER:
    "createBdProjectTaskRejectionReason",
  UPDATE_PROJECT_TASK_REJECTION_REASON_MASTER:
    "updateBdProjectTaskRejectionReason",
  GET_AGREEMENT_TYPE_MASTER: "getBdAgreementType",
  CREATE_AGREEMENT_TYPE_MASTER: "createBdAgreementType",
  UPDATE_AGREEMENT_TYPE_MASTER: "updateBdAgreementType",
  GET_COURT_ESTABLISHMENT: "getCourtEstablishment",
  CREATE_COURT_ESTABLISHMENT: "createCourtEstablishment",
  UPDATE_COURT_ESTABLISHMENT: "updateCourtEstablishment",
  GET_COURT_CASE_STATUS_MASTER: "getCourtCaseStatusMaster",
  CREATE_COURT_CASE_STATUS_MASTER: "createCourtCaseStatus",
  UPDATE_COURT_CASE_STATUS_MASTER: "updateCourtCaseStatusMaster",
  GET_COURT_CASE_TYPE_MASTER: "getCourtCaseTypeMaster",
  CREATE_COURT_CASE_TYPE: "createCourtCaseType",
  UPDATE_COURT_CASE_TYPE: "updateCourtCaseType",
  GET_REGISTRATION_FEES_RATE: "getBdRegistrationFeesRate",
  CREATE_REGISTRATION_FEES_RATE: "createBdRegistrationFeesRate",
  UPDATE_REGISTRATION_FEES_RATE: "updateBdRegistrationFeesRate",
  GET_REGISTRATION_FEES: "getBdRegistrationFees",
  CREATE_REGISTRATION_FEES: "createBdRegistrationFees",
  UPDATE_REGISTRATION_FEES: "updateBdRegistrationFees",
  GET_LIASION_PROCESSES_EXCEPTION: "getLiaisonProcessException",
  CREATE_LIASION_PROCESSES_EXCEPTION: "createLiaisonProcessException",
  UPDATE_LIASION_PROCESSES_EXCEPTION: "updateLiaisonProcessException",
  GET_LIAISON_PROCESS_FOR_EXCEPTION: "getLiaisonProcessForException",
  GET_APF_TASK_LIST: "getApfTaskList",
  CREATE_APF_TASK: "createApfTaskList",
  UPDATE_APF_TASK: "updateApfTaskList",
  GET_APF_PANEL_LIST: "getApfPanelList",
  CREATE_APF_PANEL_LIST: "createApfPanelList",
  UPDATE_APF_PANEL_LIST: "updateApfPanelList",
  GET_PROJECT_DOCUMENT_TYPE: "getProjectDocumentType",
  CREATE_PROJECT_DOCUMENT_TYPE: "createBdProjectDocumentType",
  UPDATE_PROJECT_DOCUMENT_TYPE: "updateBdProjectDocumentType",
  // GET_LIASISON_DELAY_REASON:"getliasionDelayReason",
  // CREATE_LIASISON_DELAY_REASON:"createliasionDelayReason",
  // UPDATE_LIASISON_DELAY_REASON: "updateliasionDelayReason",
  GET_LIASISON_DELAY_REASON: "getLiaisonDelayReason",
  CREATE_LIASISON_DELAY_REASON: "createLiaisonDelayReason",
  UPDATE_LIASISON_DELAY_REASON: "updateLiaisonDelayReason",
  GET_BANK: "getBank",
  CREATE_BANK: "createBank",
  UPDATE_BANK: "updateBank",
  GET_BANK_CATEGORY: "getBankCategory",
  GET_LOAN_TASK: "getLoanTask",
  GET_LOAN_PROCESS: "getLoanProcess",
  CREATE_LOAN_TASK: "createLoanTask",
  UPDATE_LOAN_TASK: "updateLoanTask",
  CREATE_LOAN_PANEL: "createLoanPanel",
  UPDATE_LOAN_PANEL: "updateLoanPanel",
  CREATE_LOAN_PROCESS: "createLoanProcess",
  UPDATE_LOAN_PROCESS: "updateLoanProcess",
  GET_CIVIL_PROJECT_TASK_UOM: "getCivilProjectTaskUOM",
  CREATE_CIVIL_PROJECT_TASK_UOM: "createCivilProjectTaskUOM",
  UPDATE_CIVIL_PROJECT_TASK_UOM: "updateCivilProjectTaskUOM",
  GET_PROJECT_TASK_TRANSACTION: "getBdProjectTaskTransaction",
  CREATE_PROJECT_TASK_TRANSACTION: "createBdProjectTaskTransaction",
  UPDATE_PROJECT_TASK_TRANSACTION: "updateBdProjectTaskTransaction",
  CREATE_PROJECT_DROP_REASON: "createProjectDropReason",
  UPDATE_PROJECT_DROP_REASON: "updateProjectDropReason",
  GET_BD_TASK_APPROVAL: "getBdTaskApproval",
  CREATE_BD_TASK_APPROVAL: "createBDTaskApproval",
  UPDATE_BD_TASK_APPROVAL: "updateBDTaskApproval",
  GET_SUB_REGISTRAR_OFFICE: "getSubRegisterOfficeMaster",
  CREATE_SUB_REGISTRAR_OFFICE: "createSubRegisterOffice",
  UPDATE_SUB_REGISTRAR_OFFICE: "updateSubRegisterOffice",
  GET_SUB_REGISTRAR_MAPPING: "getSubRegistrarMapping",
  CREATE_SUB_REGISTRAR_MAPPING: "createSubRegistrarMapping",
  UPDATE_SUB_REGISTRAR_MAPPING: "updateSubRegistrarMapping",
  GET_CIVIL_PROJECT_TASK: "getCivilTask",
  CREATE_CIVIL_PROJECT_TASK: "createCivilTask",
  UPDATE_CIVIL_PROJECT_TASK: "updateCivilTask",
  GET_CIVIL_PROCESS_M: "getCivilProcess",
  CREATE_CIVIL_PROJECT_PROCESS: "createCivilProcess",
  UPDATE_CIVIL_PROJECT_PROCESS: "updateCivilProcess",
  GET_CIVIL_RESOURCE: "getCivilResource",
  GET_CIVIL_RESOURCE_CATEGORY: "getCivilResourceCategory",
  CREATE_CIVIL_RESOURCE: "createCivilResource",
  UPDATE_CIVIL_RESOURCE: "updateCivilResource",
  GET_LIAISON_PROCESS_APPLICABILITY_REASON:
    "getLiaisonProcessApplicabilityReason",
  CREATE_LIAISON_PROCESS_APPLICABILITY_REASON:
    "createLiaisonProcessApplicabilityReason",
  UPDATE_LIAISON_PROCESS_APPLICABILITY_REASON:
    "updateLiaisonProcessApplicabilityReason",
  CREATE_CIVIL_PROJECT_RESOURCE: "createCivilProjectResource",
  UPDATE_CIVIL_PROJECT_RESOURCE: "updateCivilProjectResource",
  GET_CIVIL_OBJECT_PRODUCTIVITY: "getCivilProjectResourceProductivity",
  CREATE_LANLORD_MEETING: "createLandLordMeeting",
  GET_LANDLORD_MEETING: "getLandLordMeeting",
  GET_LANDLORD_MEETING_STAGE: "getLandLordMeetingStage",
  GET_LANDLORD_MEETING_PARTICULAR: "getLandLordMeetingParticular",
  // CREATE_CIVIL_OBJECT_PRODUCTIVITY: "createCivilProjectResourceProductivity",
  CREATE_CIVIL_RESOURCE_PRODUCTIVITY: "createCivilProjectResourceProductivity",
  COPY_CIVIL_PROJECT_RESOURCE: "copyCivilProjectResource",
  COPY_CIVIL_PROJECT_PRODUCTIVITY: "copyCivilResourceProductivity",
  UPDATE_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY:
    "updateCivilProjectResourceProductivity",
  GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE_CATEGORY:
    "getCivilProjectResourceFilterResourceCategory",
  GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE:
    "getCivilProjectResourceFilterResource",
  GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE_CATEGORY:
    "getCivilProjectResourceProductivityFilterResourceCategory",
  GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE:
    "getCivilProjectResourceProductivityFilterResource",
  IMPORT_CIVIL_PROJECT_RESOURCE: "importCivilProjectResource",
  IMPORT_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY:
    "importCivilProjectResourceProductivity",
  GET_USER_DESIGNATION: "getUserDesignation",
  GET_USER_ROLE_FUNCTIONALITY: "getUserRoleFunctionality",
  UPDATE_USER_ROLE_FUNCTIONALITY: "updateUserRoleFunctionality",
  CREATE_USER_ROLE_FUNCTIONALITY: "createUserRoleFunctionality",
  GET_PUBLIC_HOLIDAY_LIST: "getPublicHoliday",
  CREATE_PUBLIC_HOLIDAY_LIST: "createPublicHoliday",
  UPDATE_PUBLIC_HOLIDAY_LIST: "updatePublicHoliday",
  GET_NEWS_LETTER_CATEGORY: "getNewsLetterCategory",
  GET_APF_TASK_PLAN_REPORT: "getApfTaskPlanReport",
  CREATE_PROJECT_ZONE: "createProjectZone",
  GET_YEAR: "getYear",

  //Land Lord Meeting (Sadanand)
  ADD_LAND_LORD_MEETING_PARTICULAR_REMARK: "addLandLordMeetingParticularRemark",
  GET_LAND_LORD_MEETING_PARTICULAR_REMARK: "getLandLordMeetingParticularRemark",
  GET_LAND_LORD_MEETING_ATTENDEE: "getLandLordMeetingAttendee",
  GET_LAND_LORD_MEETING_PARTICULAR: "getLandLordMeetingParticular",
  CREATE_LAND_LORD_MEETING_PARTICULAR: "createLandLordMeetingParticular",
  UPDATE_LAND_LORD_MEETING_PARTICULAR: "updateLandLordMeetingParticular",
  GET_LANDLORD_MEETING_DETAIL: "getLandLordMeetingDetail",
  UPDATE_LANDLORD_MEETING: "updateLandLordMeeting",
  GET_LAND_LORD_MEETING_STAGE_MASTER: "getLandLordMeetingStage",
  CREATE_LAND_LORD_MEETING_STAGE: "createLandLordMeetingStage",
  UPDATE_LAND_LORD_MEETING_STAGE: "updateLandLordMeetingStage",
  ADD_LAND_LORD_MEETING_ATTENDEE: "addLandLordMeetingAttendee",
  ADD_LAND_LORD_MEETING_MOM: "addLandLordMeetingMom",
  GET_LAND_LORD_MEETING_MOM: "getLandLordMeetingMom",
  GET_LIAISON_PROCESS_DEPENDENT: "getLiaisonProcessDependent",
  UPDATE_LAND_LORD_MEETING_STATUS: "updateLandLordMeetingStatus",
  CREATE_SALARY_ADVANCE: "createSalaryAdvance",
  GET_SALARY_ADVANCE_DEDUCTION_HISTORY: "getSalaryAdvanceDeductionHistory",
  GET_SALARY_ADVANCE: "getSalaryAdvance",
  GET_OUT_PASS: "getOutPass",
  CREATE_OUT_PASS: "createOutPass",
  UPDATE_OUT_PASS_APPROVAL: "updateOutPassStatus",
  GET_EMPLOYEE: "getEmployee",
  GET_EMPLOYEE_ATTENDANCE: "getEmployeeAttendance",
  GET_LEAVE_TYPE: "getLeaveType",
  GET_EMPLOYEE_CHECKIN: "getEmployeeCheckIn",
  UPDATE_SALARY_ADVANCE_STATUS: "updateSalaryAdvanceStatus",
  GET_PETTY_CASH: "getPettyCash",
  GET_LEAVE_SUMMARY: "getLeaveSummary",
  GET_COMP_OFF_LEAVE_SUMMARY: "getCompOffLeaveSummary",
  // GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE:"getCivilProjectTaskPlanResource",
  GET_CIVIL_PROJECT_TASK_QUANTITY_EXPORT:
    "getCivilProjectTaskPlanQuantityExport",
  GET_BD_PROJECT_DEED_TYPE: "getBdProjectDeedType",
  UPDATE_PROJECT_FILE_MOU_DATE: "updateProjectFileMouDate",
  ADD_LIAISON_PROCESS_PLAN_MERGER: "liaisonProcessPlanMerger",
  GET_LIAISON_PROCESS_PLAN_MERGER_FILE: "getLiaisonProcessPlanMergerFile",
  GET_VEHICLE_SERVICE_REQUEST: "getVehicleServiceRequest",
  IMPORT_PROJECT_FILE_MOU_DATE: "importProjectFileMouDate",
  GET_VECHICLE_TYPE: "getVehicleType",
  GET_SERVICE_TYPE: "getServiceType",
  IMPORT_CIVIL_PROJECT_TASK_PLAN_QUANTITY: "importCivilProjectTaskPlanQuantity",
  GET_CIVIL_PROJECT_TASK_MATERIAL_EXPORT:
    "getCivilProjectTaskPlanMaterialExport",
  GET_CIVIL_PROJECT_TASK_RESOURCE_EXPORT:
    "getCivilProjectTaskPlanResourceExport",
  // IMPORT_CIVIL_PROJECT_TASK_PLAN_MATERIAL:"importCivilProjectTaskPlanQuantity",
  IMPORT_CIVIL_PROJECT_TASK_PLAN_RESOURCE: "importCivilProjectTaskPlanResource",
  UPDATE_CIVIL_PROJECT_TASK_PLAN_SCHEDULE: "updateCivilProjectTaskPlanSchedule",
  GET_BD_LAND_DISPUTE_PRIORITY: "getBdLandDisputePriority",
  GET_APF_TASK_PLAN_QUERY_TEMPLETE: "getApfTaskPlanQueryTemplete",
  IMPORT_APF_TASK_PLAN_QUERY: "importApfTaskPlanQuery",
  UPDATE_BD_LAND_DISPUTE: "updateBdLandDispute",

  //Schedule (Sadanand)
  GET_CIVIL_PROJECT_BUDGET: "getCivilProjectBudget",
  GET_CIVIL_PROJECT_QUANTITY: "getCivilProjectQuantity",
  GET_CIVIL_PROJECT_SCHEDULE_ESTIMATE: "getCivilProjectScheduleEstimate",
  CIVIL_PROJECT_ACTIVITY_DELETE: "civilProjectActivityDelete",
  CIVIL_PROJECT_WBS_DELETE: "civilProjectWbsDelete",
  GET_CIVIL_PROJECT_WBS_DETAIL: "getCivilProjectWbsDetail",
  UPDATE_CIVIL_PROJECT_PROCESS_PLAN: "updateCivilProjectProcessPlan",
  GET_CIVIL_PROJECT_PLAN_PENDING: "getCivilProjectPlanPending",

  CREATE_LIAISON_PROCESS_PLAN_EXCEPTION: "createLiaisonProcessPlanException",
  GET_LIAISON_PROCESS_PLAN_EXCEPTION: "getLiaisonProcessPlanException",
  UPDATE_LIAISON_PROCESS_PLAN_EXCEPTION: "updateLiaisonProcessPlanException",

  GET_PROJECT_TASK_PLAN_APPROVAL_FILTER_APPROVAL_BY:
    "getProjectTaskPlanApprovalFilterApprovalBy",
  GET_LIAISON_PROCESS_PLAN_APPROVAL_FILTER_APPROVER_BY:
    "getLiaisonProcessPlanApprovalFilterApprover",
  GET_LIAISON_DOCUMENTATION_TASK_PLAN_APPROAVAL_FILTER_APPROVER:
    "getLiaisonDocumentationTaskPlanApprovalFilterApprover",
  UPDATE_PROJECT_PHASE: "updateProjectPhase",
  GET_LANDLORD_MEETING_DETAIL_PRINT: "getLandLordMeetingDetail",
  RESCHEDULE_LANDLORD_MEETING: "rescheduleLandLordMeeting",
  GET_APF_TASK_PLAN_FILE: "getApfTaskPlanFile",
  GET_APF_TASK_PLAN_QUERY_FILTER_FILE: "getApfTaskPlanQueryFilterFile",
  GET_LIAISON_PROCESS_AUTHORITY_MAP: "getLiaisonProcessAuthorityMap",
  UPDATE_LIAISON_PROCESS_PLANNING_AUTHORITY:
    "updateLiaisonProcessPlanningAuthority",
  GET_APF_TASK_PLAN_QUERY_FILTER_RESPONSIBLE:
    "getApfTaskPlanQueryFilterResponsible",
  GET_APF_TASK_PLAN_QUERY_FILTER_ACCOUNTABLE:
    "getApfTaskPlanQueryFilterAccountable",
  GET_APF_TASK_PLAN_ACCOUNTABLE_FILTER: "getApfTaskPlanAccountableFilter",
  GET_APF_TASK_PLAN_OWNER_FILTER: "getApfTaskPlanOwnerFilter",
  UPDATE_PROJECT_ZONE: "updateProjectZone",
  UPDATE_LIAISON_PROCESS_PLAN_DEPENDENCY_EXCEPTION:
    "updateLiaisonProcessPlanException",
  UPDATE_CIVIL_PROJECT_TASK_PLAN_DEPENDENCY:
    "updateCivilProjectTaskPlanDependancy",
  DELETE_CIVIL_PROJECT_TASK_PLAN_DEPENDENCY:
    "deleteCivilProjectTaskPlanDependancy",
  GET_CIVIL_PROJECT_SCHEDULE: "getCivilProjectSchedule",

  GET_DESIGNATION: "getDesignation",
  GET_DEPARTMENT_MASTER: "getDepartmentMaster",
  CREATE_DESIGNATION: "createDesignation",
  UPDATE_DESIGNATION: "updateDesignation",
  CREATE_DEPARTMENT: "createDepartment",
  UPDATE_DEPARTMENT: "updateDepartment",
  CIVIL_PROJECT_WBS_DELETE_BULK: "civilProjectWbsDeleteBulk",
  CIVIL_PROJECT_ACTIVITY_DELETE_BULK: "civilProjectActivityDeleteBulk",
  GET_CIVIL_RESOURCE_FOR_ACTIVITY: "getCivilResourceForActivity",
  GET_CIVIL_MATERIAL_FOR_ACTIVITY: "getCivilMaterialForActivity",
  DELETE_CIVIL_PROJECT_TASK_PLAN_RESOURCE: "deleteCivilProjectTaskPlanResource",
  GET_LEVEL: "getLevel",
  CREATE_LEVEL: "createLevel",
  UPDATE_LEVEL: "updateLevel",
  UPLOAD_JOB_ROLE: "uploadJobRole",
  GET_JOB_ROLE: "getJobRole",
  GET_CIVIL_PROJECT_ACTIVITY_GANTT_CHART: "getCivilProjectActivityGanttChart",
  GET_LANDLORD_MEETING_REPORT: "getLandLordMeetingReport",
  GET_LANDLORD_MEETING_REPORT_DETAIL_PRINT: "getLandLordMeetingReport",
  GET_CIVIL_PROJECT_PLAN_DETAIL: "getCivilProjectPlanDetail",
  GET_CIVIL_PROJECT_PLAN_FROM_TASK_PLAN_DETAIL:
    "getCivilProjectPlanFromTaskPlanDetail",
  GET_PROJECT_CATEGORY: "getProjectCategory",
  CREATE_PROJECT_CATEGORY: "createProjectCategory",
  UPDATE_PROJECT_CATEGORY: "updateProjectCategory",
  GET_BD_PROJECT_TASK_PROJECT_CATEGORY_MAP:
    "getBdProjectTaskProjectCategoryMap",
  UPDATE_BD_PROJECT_TASK_PROJECT_CATEGORY_MAP:
    "updateBdProjectTaskProjectCategory",

  //Work Order Process (Sadanand)
  GET_WORK_ORDER_PROCESS: "getCivilWorkOrderProcess",
  CREATE_WORK_ORDER_PROCESS: "createCivilWorkOrderProcess",
  UPDATE_WORK_ORDER_PROCESS: "updateCivilWorkOrderProcess",
  GET_WORK_ORDER_TASK: "getCivilWorkOrderTask",
  CREATE_WORK_ORDER_TASK: "createCivilWorkOrderTask",
  UPDATE_WORK_ORDER_Task: "updateCivilWorkOrderTask",
  GET_PROJECT_FILE_FILTER_PROJECT: "getProjectFileFilterProject",
  GET_PROJECT_PRIORITY: "getProjectPriority",

  GET_PROFITABLE_REPORT_GROUP: "getProfitableReportGroup",
  CREATE_PROFITABLE_REPORT_GROUP: "createProfitableReportGroup",
  UPDATE_PROFITABLE_REPORT_GROUP: "updateProfitableReportGroup",

  GET_PROFITABLE_REPORT_CATEGORY: "getProfitableReportCategory",
  CREATE_PROFITABLE_REPORT_CATEGORY: "createProfitableReportCategory",
  UPDATE_PROFITABLE_REPORT_CATEGORY: "updateProfitableReportCategory",

  GET_PROFITABLE_REPORT_ITEM: "getProfitableReportItem",
  CREATE_PROFITABLE_REPORT_ITEM: "createProfitableReportItem",
  UPDATE_PROFITABLE_REPORT_ITEM: "updateProfitableReportItem",
  GET_LAND_ACQUISITION_KPI_REPORT: "landAcquisitionKpiReport",

  GET_PROJECT_MOU_KPI_REPORT: "getProjectMoUKPIReport",
  GET_LAND_ACQUISITION_REPORT: "getLandAcquisitionKPIReport",
  GET_APF_TASK_PLAN_QUERY_REPORT: "getApfTaskPlanQueryReport",
  GET_PROJECT_PHASE_MASTER: "getProjectPhaseMaster",
  CREATE_PROJECT_PHASE_MASTER: "createProjectPhaseMaster",
  UPDATE_PROJECT_PHASE_MASTER: "updateProjectPhaseMaster",
  GET_LAND_ACQUISITION_REPORT_ITEM: "getLandAcquisitionKPIReportItem",

  GET_PO_REJECTION_REPORT: "getPoRejectionReport",
  GET_PO_CYCLE_REPORT: "getPoCycleReport",
  GET_STOCK_PROJECT: "getStockProject",
  GET_PROFITABILITY_REPORT: "getProfitabilityReport",

  //work-order (Sadanand)
  GET_WORK_ORDER_PROJECT: "getWorkOrderProject",
  GET_COMPANY_PROJECT_MAP: "getCompanyProjectMap",
  GET_VENDOR_TYPE: "getVendorType",
  GET_WORK_ORDER_FILTER_VENDOR: "getWorkOrderFilterVendor",
  CREATE_WORK_ORDER: "createWorkOrder",
  GET_WORK_ORDER: "getWorkOrder",
  UPDATE_WORK_ORDER: "updateWorkOrder",
  CREATE_WORK_ORDER_TERM: "createWorkOrderTerm",
  GET_WORK_ORDER_TERMS: "getWorkOrderTerms",
  DELETE_WORK_ORDER_TERM: "deleteWorkOrderTerm",
  GET_WORK_ORDER_DETAIL: "getWorkOrderDetail",
  GET_WORK_ORDER_PROJECT_WBS: "getWorkOrderProjectWbs",
  GET_WORK_ORDER_PROJECT_ACTIVITY: "getWorkOrderProjectActivity",
  GET_WORK_ORDER_TASK_FOR_ITEM: "getWorkOrderTaskForItem",
  CREATE_WORK_ORDER_ITEM: "createWorkOrderItem",
  GET_WORK_ORDER_ITEM: "getWorkOrderItem",
  DELETE_WORK_ORDER_ITEM: "deleteWorkOrderItem",
  GET_WORK_ORDER_FILTER_PROJECT: "getWorkOrderFilterProject",
  UPDATE_WORK_ORDER_STATUS: "updateWorkOrderStatus",
  GET_WORK_ORDER_CATEGORY: "getCivilWorkOrderCategory",
  CREATE_WORK_ORDER_CATEGORY: "createCivilWorkOrderCategory",
  UPDATE_WORK_ORDER_CATEGORY: "updateCivilWorkOrderCategory",
  GET_CIVIL_PROJECT_ACTIVITY_PROGRESS: "getCivilProjectActivityProgress",
  UPDATE_WORK_ORDER_DUE_DATE: "updateWorkOrderDueDate",

  //project-loan (Sadanand)
  CREATE_PROJECT_LOAN: "createProjectLoan",
  GET_PROJECT_LOAN: "getProjectLoan",
  GET_PROJECT_LOAN_STATUS: "getProjectLoanStatus",
  GET_PROJECT_LOAN_DETAIL: "getProjectLoanDetail",
  CREATE_PROJECT_LOAN_PROCESS: "createProjectLoanProcess",
  GET_LOAN_PROCESS_FOR_PROCESS_PLAN_ADD: "getLoanProcessForProcessPlanAdd",
  GET_LOAN_PANEL_FOR_PROCESS_PLAN_ADD: "getLoanPanelForProcessPlanAdd",
  GET_LAND_ACQUISITION_OVERALL_REPORT: "getLandAquisationOveralReport",
  GET_CIVIL_RESOURCE_REPORT: "getCivilResourceReport",
  GET_CIVIL_RESOURCE_REPORT_NON_MATERIAL: "getCivilResourceReportNonMaterial",
  PROJECT_SPLIT: "projectSplit",
  CREATE_PROJECT_MORTGAGE: "createProjectMortgage",
  GET_PROJECT_MORTGAGE: "getProjectMortgage",
  UPDATE_PROJECT_MORTGAGE_STATUS: "updateProjectMortgageStatus",
  DELETE_PROJECT_MORTGAGE: "deleteProjectMortgage",

  //vendor-machine
  CREATE_VENDOR_MACHINE: "createVendorMachine",
  GET_VENDOR_MACHINE: "getVendorMachine",
  GET_VENDOR_MACHINE_DETAIL: "getVendorMachineDetail",
  UPADTE_VENDOR_MACHINE: "updateVendorMachine",
  CREATE_PROJECT_MACHINE_VENDOR_MAP: "createProjectMachineVendorMap",
  GET_PROJECT_MACHINE_VENDOR_MAP: "getProjectMachineVendorMap",
  GET_PROJECT_MACHINE_VENDOE_MAP_DETAIL: "getProjectMachineVendorMapDetail",
  GET_LOAN_PROCESS_STATUS: "getLoanProcessStatus",
  CREATE_LOAN_PROCESS_STATUS: "createLoanProcessStatus",
  UPDATE_LOAN_PROCESS_STATUS: "updateLoanProcessStatus",
  GET_PROJECT_LOAN_PROCESS: "getLoanProcessPlan",
  GET_PROJECT_MORTGAGE_DETAILS: "getProjectMortgageDetail",
  UPDATE_PROJECT_MORTGAGE_DETAIL: "updateProjectMortgageDetail",
  LOAN_PROCESS_PLAN_START: "loanProcessPlanStart",
  GET_DEPARTMENT_DESIGNATION: "getDepartmentDesignation",
  GET_PROJECT_MORTGAGE_ELIGIBLE_FILE: "getProjectMortgageEligibleFile",

  // Project Loan (Sadanand)
  UPDATE_PROJECT_LOAN_STATUS: "updateProjectLoanStatus",
  UPDATE_PROJECT_LOAN_DETAIL: "updateProjectLoanDetail",
  UPDATE_LOAN_PROCESS_PLAN_STATUS: "updateLoanProcessPlanStatus",
  GET_LOAN_PROCESS_STATUS_FOR_UPDATE: "getLoanProcessStatusForUpdate",
  UPDATE_LOAN_PROCESS_PLAN_APPLICABILITY_STATUS:
    "updateLoanProcessPlanApplicabilityStatus",
  GET_PROJECT_LOAN_FILTER_PROJECT: "getProjectLoanFilterProject",
  GET_PROJECT_LOAN_FILTER_BANK: "getProjectLoanFilterBank",
  GET_LOAN_PROCESS_PLAN_FILTER_PROJECT: "getLoanProcessPlanFilterProject",
  GET_LOAN_PROCESS_PLAN_FILTER_BANK: "getLoanProcessPlanFilterBank",
  GET_LOAN_PROCESS_PLAN_FILTER_PROCESS: "getLoanProcessPlanFilterProcess",
  GET_LOAN_PROCESS_PLAN_FILTER_PANEL: "getLoanProcessPlanFilterPanel",
  CREATE_LOAN_PROCESS_PLAN_QUERY: "createLoanProcessPlanQuery",
  GET_LOAN_PROCESS_PLAN_FOR_QUERY_CREATE: "getLoanProcessPlanForQueryCreate",
  GET_LOAN_PROCESS_PLAN_QUERY: "getLoanProcessPlanQuery",
  GET_LOAN_PROCESS_PLAN_QUERY_DETAIL: "getLoanProcessPlanQueryDetail",
  UPDATE_LOAN_PROCESS_PLAN_QUERY_STATUS: "updateLoanProcessPlanQueryStatus",
  UPDATE_LOAN_PROCESS_PLAN_QUERY_OWNER: "updateLoanProcessPlanQueryOwner",
  UPDATE_LOAN_PROCESS_PLAN_QUERY: "updateLoanProcessPlanQuery",
  GET_LOAN_PROCESS_PLAN_QUERY_FILTER_PROJECT:
    "getLoanProcessPlanQueryFilterProject",
  GET_LOAN_PROCESS_PLAN_QUERY_FILTER_BANK: "getLoanProcessPlanQueryFilterBank",
  GET_LOAN_PROCESS_PLAN_QUERY_FILTER_PANEL:
    "getLoanProcessPlanQueryFilterPanel",
  GET_LOAN_PROCESS_PLAN_QUERY_FILTER_PROCESS:
    "getLoanProcessPlanQueryFilterProcess",
  GET_LOAN_PROCESS_PLAN_QUERY_FILTER_RESPONSIBLE:
    "getLoanProcessPlanQueryFilterResponsible",
  GET_LOAN_PROCESS_PLAN_QUERY_FILTER_ACCOUNTABLE:
    "getLoanProcessPlanQueryFilterAccountable",
  CREATE_LOAN_PROCESS_PLAN_EXTRA: "createLoanProcessPlanExtra",
  IMPORT_LOAN_PROCESS_PLAN_QUERY: "importLoanProcessPlanQuery",
  GET_LOAN_PROCESS_PLAN_FILTER_RESPONSIBLE:
    "getLoanProcessPlanFilterResponsible",
  GET_LOAN_PROCESS_PLAN_FILTER_ACCOUNTABLE:
    "getLoanProcessPlanFilterAccountable",
  GET_LOAN_PROCESS_PLAN_QUERY_TEMPLATE: "getLoanProcessPlanQueryTemplete",
  GET_LOAN_PROCESS_PLAN_QUERY_REPORT: "getLoanProcessPlanQueryReport",
  GET_WORK_ORDER_TASK_PLAN_RESOURCE: "getWorkOrderTaskPlanResource",
  GET_ACTUAL_ENTRY_TASK_PLAN_RESOURCE: "getActualEntryTaskPlanResource",

  //Actual entry for Machine (Sadanand)
  GET_ACTUAL_ENTRY_VENDOR_MACHINE: "getActualEntryVendorMachine",
  CREATE_CIVIL_ACTUAL_ENTRY_MACHINE: "createCivilActualEntryMachine",
  UPDATE_TASK_STATUS: "updateTaskStatus",
  UPDATE_TASK: "updateTask",
  GET_CIVIL_ACTUAL_ENTRY_MACHINE: "getCivilActualEntryMachine",
  UPDATE_CIVIL_ACTUAL_ENTRY_MACHINE: "updateCivilActualEntryMachine",
  GET_CIVIL_ACTUAL_ENTRY_MACHINE_DETAIL: "getCivilActualEntryMachineDetail",
  UPDATE_CIVIL_ACTUAL_ENTRY_MACHINE_DETAIL:
    "updateCivilActualEntryMachineDetail",
  UPDATE_CIVIL_ACTUAL_ENTRY_REVIEW_STATUS: "updateCivilActualEntryReviewStatus",
  UPDATE_CIVIL_ACTUAL_ENTRY_APPROVAL_STATUS:
    "updateCivilActualEntryAppovalStatus",
  GET_CIVIL_ACTUAL_ENTRY_MACHINE_FILTER_MACHINE:
    "getCivilActualEntryFilterMachine",
  GET_ACTIVITY_QUALITY_CHECK_POINT: "getActivityQualityCheckPoint",
  CREATE_ACTIVITY_QUALITY_CHECK_POINT: "createActivityQualityCheckPoint",
  UPDATE_ACTIVITY_QUALITY_CHECK_POINT: "updateActivityQualityCheckPoint",
  GET_ACTUAL_REJECTION_REASON: "getActualRejectionReason",
  CRAETE_ACTUAL_REJECTION_REASON: "createActualRejectionReason",
  UPDATE_ACTIVITY_REJECTION_REASON: "updateActualRejectionReason",

  // Vendor Nithish ------- start
  GET_VENDOR: "getVendor",
  GET_CONSTITUTION_OF_FIRM: "getConstitutionOfFirm",
  CREATE_VENDOR: "createVendor",
  GET_VENDOR_DOCUMENTS: "getVendorDocuments",
  DELETE_VENDOR_DOCUMENTS: "deleteVendorDocument",
  GET_VENDOR_DOCUMENT_TYPE: "getVendorDocumentType",
  UPLOAD_VENDOR_DOCUMENTS: "uploadVendorDocuments",
  GET_VENDOR_SERVICE: "getVendorService",
  GET_VENDOR_SERVICE_MASTER: "getVendorServiceMaster",
  CREATE_VENDOR_SERVICE_MASTER: "createVendorService",
  UPDATE_VENDOR_SERVICE: "updateVendorService",
  GET_VENDOR_CONTACT: "getVendorContact",
  CREATE_VENDOR_CONTACT: "createVendorContact",
  UPDATE_VENDOR_CONTACT: "updateVendorContact",
  GET_VENDOR_ACCOUNT: "getVendorAccount",
  CREATE_VENDOR_ACCOUNT: "createVendorAccount",
  GET_VENDOR_ACCOUNT_DOCUMENT_TYPE: "getVendorAccountDocumentType",
  GET_VENDOR_ADDRESS: "getVendorAddress",
  CREATE_VENDOR_ADDRESS: "createVendorAddress",
  GET_STATE: "getState",
  GET_VENDOR_ACTION_HISTORY: "getVendorActionHistory",
  UPDATE_VENDOR_STATUS: "updateVendorStatus",
  UPDATE_VENDOR: "updateVendor",
  UPDATE_VENDOR_MSME: "uploadVendorMsme",
  UPLOAD_VENDOR_GST: "uploadVendorGst",
  UPLOAD_VENDOR_PAN: "uploadVendorPan",
  GET_VENDOR_DETAILS: "getVendorDetail",
  UPDATE_VENDOR_ADDRESS: "updateVendorAddress",
  UPDATE_VENDOR_ACCOUNT: "updateVendorAccount",

  // Vendor Nithish ------- end

  UPDATE_LIAISON_PROCESS_TASK_PLAN_ORDER: "updateLiaisonProcessTaskPlanOrder",
  GET_ACCOUNT_PAYMENT_FILTER_COMPANY: "getAccountPaymentFilterCompany",
  GET_ACCOUNT_PAYMENT_FILTER_PROJECT: "getAccountPaymentFilterProject",
  GET_ACCOUNT_PAYMENT_FILTER_REQUESTED_BY: "getAccountPaymentFilterRequestedBy",
  GET_ACCOUNT_PAYMENT_FILTER_CATEGORY: "getAccountPaymentFilterCategory",
  CHANGE_PROJECT_TASK_PLAN: "changeProjectTaskPlan",
  GET_TASK_ACCOUNTABLE_FILTER: "getTaskAccountableFilter",
  GET_ACTUAL_ENTRY_VENDOR_LABOUR: "getActualEntryVendorLabour",

  //Civil Actual Entry (Sadanand)
  CREATE_CIVIL_ACTUAL_ENTRY_LABOUR: "createCivilActualEntryLabour",
  UPDATE_CIVIL_ACTUAL_ENTRY_LABOUR: "updateCivilActualEntryLabour",
  UPDATE_CIVIL_ACTUAL_ENTRY_LABOUR_DETAIL: "updateCivilActualEntryLabourDetail",
  GET_ACTUAL_ENTRY_VENDOR_CONTRACT: "getActualEntryVendorContract",
  CREATE_CIVIL_ACTUAL_ENTRY_CONTRACT: "createCivilActualEntryContract",
  GET_CIVIL_ACTUAL_ENTRY_LABOUR: "getCivilActualEntryLabour",
  GET_CIVIL_ACTUAL_ENTRY_LABOUR_DETAIL: "getCivilActualEntryLabourDetail",
  GET_CIVIL_ACTUAL_ENTRY_CONTRACT: "getCivilActualEntryContract",
  GET_CIVIL_ACTUAL_ENTRY_CONTRACT_DETAIL: "getCivilActualEntryContractDetail",
  GET_CIVIL_ACTUAL_ENTRY_FILTER_PROJECT: "getCivilActualEntryFilterProject",
  GET_CIVIL_ACTUAL_ENTRY_FILTER_WBS: "getCivilActualEntryFilterWbs",
  GET_CIVIL_ACTUAL_ENTRY_FILTER_ACTIVITY: "getCivilActualEntryFilterActivity",
  GET_CIVIL_ACTUAL_ENTRY_FILTER_VENDOR: "getCivilActualEntryFilterVendor",
  UPDATE_CIVIL_ACTUAL_ENTRY_CONTRACT: "updateCivilActualEntryContract",
  UPDATE_CIVIL_ACTUAL_ENTRY_CONTRACT_DETAIL:
    "updateCivilActualEntryContractDetail",
  GET_GST: "getGst",
  GET_VENDOR_CHANGE_REQUEST: "getVendorChangeRequest",
  GET_VENDOR_CHANGE_REQUEST_DETAILS: "getVendorChangeRequestDetails",
  UPDATE_VENDOR_CHANGE_REQUEST: "updateVendorChangeRequest",
  GET_TDS: "getTds",
  CREATE_CIVIL_PROJECT_BILL: "createCivilProjectBill",

  //EPA Start (Sadanand)
  CREATE_APPRAISAL_QUESTION: "createAppraisalQuestion",
  GET_APPRAISAL_QUESTION: "getAppraisalQuestion",
  UPDATE_APPRAISAL_QUESTION: "updateAppraisalQuestion",
  CREATE_APPRAISAL_QUESTION_OPTION: "createAppraisalQuestionOption",
  GET_APPRAISAL_QUESTION_OPTION: "getAppraisalQuestionOption",
  UPDATE_APPRAISAL_QUESTION_OPTION: "updateAppraisalQuestionOption",
  GET_EMPLOYEE_APPRAISAL_QUESTION: "getEmployeeAppraisalQuestion",
  CREATE_EMPLOYEE_APPRAISAL: "createEmployeeAppraisal",
  GET_EMPLOYEE_APPRAISAL: "getEmployeeAppraisal",
  DELETE_EMPLOYEE_APPRAISAL: "deleteEmployeeAppraisal",
  //EPA End
  GET_BD_TASK_APPROVAL_FILTER_USER: "getBdTaskApprovalFilterUser",
  GET_BD_TASK_APPROVAL_FILTER_TASK: "getBdTaskApprovalFilterTask",
  GET_BD_TASK_APPROVAL_TASK_TO_ADD: "getBdTaskApprovalTaskToAdd",
  GET_WORK_ORDER_FILTER_COMPANY: "getWorkOrderFilterCompany",
  GET_VENDOR_MACHINE_FILTER_VENDOR: "getVendorMachineFilterVendor",
  GET_VENDOR_MACHINE_FILTER_MACHINE_TYPE: "getVendorMachineFilterMachineType",
  GET_PROJECT_MACHINE_VENDOR_MAP_FILTER_VENDOR:
    "getProjectMachineVendorMapFilterVendor",
  GET_PROJECT_MACHINE_VENDOR_MAP_FILTER_MACHINE_TYPE:
    "getProjectMachineVendorMapFilterMachineType",
  GET_APPRAISAL: "getAppraisal",
  CREATE_APPRAISAL: "createAppraisal",
  UPDATE_APPRAISAL: "updateAppraisal",
  UPDATE_EMPLOYEE_APPRAISAL_QUESTION_ANSWER:
    "updateEmployeeAppraisalQuestionAnswer",
  UPDATE_PROJECT_TASK_PLAN_OWNER: "updateProjectTaskPlanOwner",
  GET_PROJECT_TASK_FOR_OWNER_UPDATE: "getProjectTaskForOwnerUpdate",
  GET_MATERIAL: "getMaterial",
  CREATE_MATERIAL: "createMaterial",
  UPDATE_MATERIAL: "updateMaterial",
  UPLOAD_MATERIAL_IMAGE: "uploadMaterialImage",
  GET_ITEM_GROUP: "getItemGroup",
  CREATE_ITEM_GROUP: "createItemGroup",
  UPDATE_ITEM_GROUP: "updateItemGroup",
  UPDATE_PROJECT_MACHINE_VENDOR_MAP_STATUS:
    "updateProjectMachineVendorMapStatus",
  UPDATE_EMPLOYEE_APPRASIAL_FINAL_SUBMIT: "updateEmployeeAppraisalFinalSubmit",
  GET_STOCK_UOM: "getStockUom",
  GET_ASSET_TYPE: "getAssetType",
  CREATE_BOOKING: "createBooking",
  GET_BOOKING: "getBooking",

  //prourement master
  GET_ASSET_GROUP: "getAssetGroup",
  CREATE_ASSET_GROUP: "createAssetGroup",
  UPDATE_ASSET_GROUP: "updateAssetGroup",
  CREATE_STOCK_UOM: "createStockUom",
  UPDATE_STOCK_UOM: "updateStockUom",
  CREATE_ASSET_TYPE: "createAssetType",
  UPDATE_ASSET_TYPE: "updateAssetType",
  GET_CUSTOMER_ORGANIZATION: "getCustomerOrganization",
  GET_CUSTOMER_WORK_EXPERIENCE: "getCustomerWorkExperience",
  GET_CUSTOMER_WORK_SECTOR: "getCustomerWorkSector",
  GET_CUSTOMER_INCOME: "getCustomerIncome",
  GET_CUSTOMER_WORK_FUNCTION: "getCustomerWorkFunction",
  GET_ENQUIRY_SOURCE: "getEnquirySource",
  GET_ENQUIRY_SOURCE_NEWSPAPER: "getEnquirySourceNewsPaper",
  GET_ENQUIRY_SOURCE_SOCIAL_MEDIA: "getEnquirySourceSocialMedia",
  CREATE_CRM_CUSTOMER_DETAILS: "createCrmCustomerDetails",
  CREATE_CIVIL_ACTIVITY_MATERIAL_SCHEDULE:
    "createCivilActivityMaterialSchedule",
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE:
    "getCivilProjectTaskPlanMaterialSchedule",
  UPDATE_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE:
    "updateCivilProjectTaskPlanMaterialSchedule",
  GET_INDENT: "getIndent",
  GET_INDENT_DETAIL: "getIndentDetail",
  GET_INDENT_PRINT: "getIndentPrint",

  GET_CRM_ENQUIRY: "getCrmEnquiry",
  GET_CRM_ENQUIRY_SOURCE: "getCrmEnquirySource",
  GET_CUSTOMER_BUDGET: "getCustomerBudget",
  GET_CRM_DIMENSION: "getCrmDimension",
  GET_CUSTOMER_CONSTRUCTIONS_PLANNING: "getCustomerConstructionPlanning",
  GET_CUSTOMER_PURCHASE_REASON: "getCustomerPurchaseReason",
  GET_CRM_SITE_SOLD: "getCrmSiteSold",
  GET_CRM_CUSTOMER_ENQUIRY: "getCrmCustomerEnquiry",

  GET_APPRAISAL_CATEGORY: "getAppraisalCategory",
  CREATE_APPRAISAL_CATEGORY: "createAppraisalCategory",
  UPDATE_APPRAISAL_CATEGORY: "updateAppraisalCategory",

  GET_APPRAISAL_RATING: "getAppraisalRating",
  CREATE_APPRAISAL_RATING: "createAppraisalRating",
  UPDATE_APPRAISAL_RATING: "updateAppraisalRating",
  UPDATE_INDENT_STATUS: "updateIndentStatus",
  GET_INDENT_FILTER_PROJECT: "getIndentFilterProject",
  GET_INDENT_FILTER_ITEM: "getIndentFilterItem",
  UPDATE_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_DETAIL:
    "updateCivilProjectTaskPlanMaterialScheduleDetail",
  CREATE_CRM_ENQUIRY_PROFILE: "createCrmEnquiryProfile",
  GET_PROJECT_DROPPED_FILTER: "getProjectDroppedFilter",
  UPDATE_APF_TASK_PLAN_APPLICATION: "updateApfTaskPlanApplicableStatus",
  CREATE_INDENT: "createIndent",
  GET_CRM_ENQUIRY_PROFILE: "getCrmEnquiryProfile",
  UPDATE_CRM_ENQUIRY_PROFILE: "updateCrmEnquiryProfile",
  UPDATE_LIAISON_TASK_PLAN_APPLICABILITY_STATUS_BULK:
    "updateLiaisonTaskPlanApplicabilityStatusBulk",
  GET_INDENT_FOR_QUOTATION: "getIndentForQuotation",
  GET_INDENT_ITEM_FOR_QUOTATION: "getIndentItemForQuotation",
  CREATE_QUOTATION: "createQuotation",
  GET_QUESTION: "getFeedbackQuestion",
  CREATE_QUESTION: "createFeedbackQuestion",
  UPDATE_QUESTION: "updateFeedbackQuestion",
  GET_QUESTION_VALUE: "getFeedbackQuestionOptionValue",
  CREATE_QUESTION_VALUE: "createFeedbackQuestionOptionValue",
  UPDATE_QUESTION_VALUE: "updateFeedbackQuestionOptionValue",
  GET_QUESTION_VALUE_SET: "getFeedbackQuestionOptionValueSet",
  CREATE_QUESTION_VALUE_SET: "createFeedbackQuestionOptionValueSet",
  UPDATE_QUESTION_VALUE_SET: "updateFeedbackQuestionOptionValueSet",
  GET_QUOTATION: "getQuotation",
  GET_QUOTATION_DETAIL: "getQuotationDetail",
  UPDATE_QUOTATION_STATUS: "updateQuotationStatus",
  UPDATE_QUOTATION_STATUS_REJECT: "updateQuotationStatusReject",
  GET_QUOTATION_FILTER_PROJECT: "getQuotationFilterProject",
  GET_QUOTATION_FILTER_ITEM: "getQuotationFilterItem",
  GET_QUOTATION_FILTER_VENDOR: "getQuotationFilterVendor",
  GET_BRAND: "getBrand",
  GET_PURCHASE_ORDER_CHARGE_MASTER: "getPurchaseOrderChargeMaster",
  CREATE_PURCHASE_ORDER: "createPurchaseOrder",
  GET_PROJECT_ADDRESS: "getProjectAddress",
  GET_SITE_VISIT_FEEDBACK_QUESTION: "getSiteVisitFeedbackQuestion",
  UPDATE_SITE_VISIT_FEEDBACK: "updateSiteVisitFeedback",
  GET_PURCHASE_ORDER: "getPurchaseOrder",
  GET_PURCHASE_ORDER_DETAIL: "getPurchaseOrderDetail",
  PURCHASE_ORDER_ITEM_DELETE: "purchaseOrderItemDelete",
  PURCHASE_ORDER_CHARGE_DELETE: "purchaseOrderChargeDelete",
  GET_PURCHASE_ORDER_FILTER_PROJECT: "getPurchaseOrderFilterProject",
  GET_PURCHASE_ORDER_FILTER_ENTITY: "getPurchaseOrderFilterEntity",
  GET_PURCHASE_ORDER_FILTER_VENDOR: "getPurchaseOrderFilterVendor",
  UPDATE_PURCHASE_ORDER_STATUS: "updatePurchaseOrderStatus",
  UPDATE_PURCHASE_ORDER: "updatePurchaseOrder",
  PURCHASE_ORDER_DELETE: "purchaseOrderDelete",
  GET_PURCHASE_ORDER_FOR_GRN: "getPurchaseOrderForGrn",
  GET_PURCHASE_ORDER_ITEM_FOR_GRN: "getPurchaseOrderItemForGrn",
  GET_SITE_VISIT_FEEDBACK: "getSiteVisitFeedback",
  GET_SITE_VISIT_FEEDBACK_DETAIL: "getSiteVisitFeedbackDetail",
  CREATE_GRN: "createGrn",
  GET_GRN: "getGrn",
  GET_GRN_DETAIL: "getGrnDetail",
  GET_GRN_DETAIL_PRINT: "getGrnDetailPrint",
  GRN_DELETE: "grnDelete",
  GET_APF_TASK_EXTRA_FILE: "getApfTaskPlanExtraFile",
  UPDATE_APF_TASK_EXTRA_FILE: "updateApfTaskPlanFile",
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_PROJECT:
    "getCivilProjectTaskPlanMaterialFilterProject",
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_PROCESS:
    "getCivilProjectTaskPlanMaterialFilterProcess",
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_TASK:
    "getCivilProjectTaskPlanMaterialFilterTask",
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_RESOURCE:
    "getCivilProjectTaskPlanMaterialFilterResource",
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_PROJECT:
    "getCivilProjectTaskPlanMaterialScheduleFilterProject",
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_PROCESS:
    "getCivilProjectTaskPlanMaterialScheduleFilterProcess",
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_TASK:
    "getCivilProjectTaskPlanMaterialScheduleFilterTask",
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_RESOURCE:
    "getCivilProjectTaskPlanMaterialScheduleFilterResource",
  GET_APPRAISAL_QUESTION_PRINT: "getAppraisalQuestionPrint",
  GET_HR_LEAVE_TYPE: "getHrLeaveType",
  CREATE_HR_LEAVE_TYPE: "createHrLeaveType",
  UPDATE_HR_LEAVE_TYPE: "updateHrLeaveType",
  GET_SUB_MENU: "getSubMenu",
  GET_CIVIL_PROJECT_DRAWING: "getCivilProjectDrawing",
  GET_PROJECT_DOCUMENT_REPOSITORY: "getProjectDocumentRepository",
  GET_PROJECT_DOCUMENT_REPOSITORY_PROJECT_FILTER:
    "getProjectDocumentRepositoryFilterProject",
  GET_PROJECT_DOCUMENT_REPOSITORY_PROJECT_FILE_FILTER:
    "getProjectDocumentRepositoryFilterProjectFile",
  GET_PROJECT_DOCUMENT_REPOSITORY_FILTER_DOCUMENT_TYPE:
    "getProjectDocumentRepositoryFilterDocumentType",
  DELETE_MATERIAL_IMAGE: "deleteMaterialImage",
  CREATE_CIVIL_WORK_INSPECTION: "createCivilWorkInspection",
  GET_CIVIL_WORK_INSPECTION: "getCivilWorkInspection",
  GET_CIVIL_WORK_INSPECTION_DETAIL: "getCivilWorkInspectionDetail",
  CREATE_CIVIL_QC_TRAINING: "createCivilQcTraining",
  GET_CIVIL_QC_TRAINING: "getCivilQcTraining",
  UPDATE_CIVIL_QC_TRAINING_ATTENDEE: "updateCivilQcTrainingAttendee",
  GET_CIVIL_QC_TRAINING_ATTENDEE: "getCivilQcTrainingAttendee",
  GET_CIVIL_QC_TRAINING_DETAIL: "getCivilQcTrainingDetail",
  UPDATE_CIVIL_QC_TRAINING_STATUS: "updateCivilQcTrainingStatus",
  CREATE_PROJECT_FILE_MOVE: "moveProjectFile",
  GET_TO_DO_CATEGORY: "getToDoCategory",
  CREATE_TO_DO_CATEGORY: "createToDoCategory",
  UPDATE_TO_DO_CATEGORY: "updateToDoCategory",
  UPDATE_CIVIL_QC_TRAINING: "updateCivilQcTraining",
  UPDATE_IDENTIFICATION_OF_REVENUE_CORRECTION:
    "updateIdentificationOfRevenueCorrection",
  UPDATE_MATERIAL_SECONDARY_UOM: "updateMaterialSecondaryUom",
  GET_MATERIAL_DETAIL: "getMaterialDetail",
  GET_MATERIAL_BRAND: "getMaterialBrand",
  CREATE_MATERIAL_BRAND: "createMaterialBrand",
  UPDATE_MATERIAL_BRAND: "updateMaterialBrand",
  UPDATE_MATERIAL_SECONDARY_UOM_STATUS: "updateMaterialSecondaryUomStatus",
  UPDATE_MATERIAL_BRAND_STATUS: "updateMaterialBrandStatus",

  /* -------------------------------------------------------------------------- */
  /*                          //sadanand (EPA-related)                          */
  /* -------------------------------------------------------------------------- */
  GET_CUSTOMER_EMPLOYMENT_TYPE: "getCustomerEmploymentType",
  GET_CUSTOMER_CURRENT_LIVING: "getCustomerCurrentLiving",
  GET_CUSTOMER_CRITICAL_FACTOR: "getCustomerCriticalFactor",
  GET_CUSTOMER_SUITABLE_TIME: "getCustomerSuitableTime",

  //EPA (Sadanand)
  UPDATE_APPRAISAL_QUESTION_DESIGNATION: "updateAppraisalQuestionDesignation",
  GET_HR_APPRAISAL_QUESTION_DESIGNATION_FOR_UPDATE:
    "getHrAppraisalQuestionDesignationForUpdate",
  GET_HR_APPRAISAL_QUESTION_DESIGNATION: "getHrAppraisalQuestionDesignation",
  GET_APPRAISAL_QUESTION_CATEGORY_FILTER: "getAppraisalQuestionCategoryFilter",
  GET_APPRAISAL_QUESTION_DEPARTMENT_FILTER:
    "getAppraisalQuestionDepartmentFilter",
  GET_APPRAISAL_QUESTION_DESIGNATION_FILTER:
    "getAppraisalQuestionDesignationFilter",
  GET_HR_APPRAISAL_QUESTION_OPTION: "getHrAppraisalQuestionOption",
  GET_HR_APPRAISAL_QUESTION_OPTION_DETAIL: "getHrAppraisalQuestionOptionDetail",

  //QC
  GET_CIVIL_QC_TRAINING_FILTER_PROJECT: "getCivilQcTrainingFilterProject",
  GET_CIVIL_QC_TRAINING_FILTER_PROCESS: "getCivilQcTrainingFilterProcess",
  GET_CIVIL_QC_TRAINING_FILTER_TASK: "getCivilQcTrainingFilterTask",
  GET_CREDIT_DURATION: "getCreditDuration",

  //Enquiry Profile (Sadanand)
  GET_CRM_BOOKING_ENQUIRY_PROFILE: "getCrmBookingEnquiryProfile",
  CREATE_CRM_CUSTOMER_PROFILE: "createCrmCustomerProfile",
  GET_PROJECT_SUB_TASK: "getBdProjectSubTask",
  CREATE_PROJECT_SUB_TASK: "createBdProjectSubTask",
  UPDATE_PROJECT_SUB_TASK: "updateBdProjectSubTask",

  //CRM Meeting
  GET_CRM_MEETING: "getCrmMeeting",
  CREATE_CRM_MEETING: "createCrmMeeting",
  GET_CRM_MEETING_CATEGORY: "getCrmMeetingCategory",
  UPDATE_CRM_MEETING_ATTENDEE: "updateCrmMeetingAttendee",
  GET_CRM_MEETING_ATTENDEE: "getCrmMeetingAttendee",
  GET_CRM_MEETING_DETAIL: "getCrmMeetingDetail",
  UPDATE_CRM_MEETING_STATUS: "updateCrmMeetingStatus",
  UPDATE_CRM_MEETING: "updateCrmMeeting",
  GET_CRM_MEETING_SOURCE: "getCrmMeetingSource",
  GET_CRM_MEETING_CHANNEL_PARTNER_INFO: "getCrmMeetingChannelPartnerInfo",
  GET_CRM_MEETING_CHANNEL_CLIENT_INFO: "getCrmMeetingClientInfo",
  GET_CRM_MEETING_STM_INFO: "getCrmMeetingStmInfo",
  GET_CRM_MEETING_PREFERRED_LOCATION: "getCrmPreferredLocation",
  ADD_CRM_MEETING_PREFERRED_LOCATION: "createCrmPreferredLocation",
  UPDATE_CRM_MEETING_PREFERRED_LOCATION: "updateCrmPreferredLocation",
  GET_CRM_SOURCE_GROUP: "getCrmSourceGroup",
  CREATE_CRM_SOURCE_GROUP: "createCrmSourceGroup",
  UPDATE_CRM_SOURCE_GROUP: "updateCrmSourceGroup",
  GET_CRM_SOURCE_CATEGORY: "getCrmSourceCategory",
  CREATE_CRM_SOURCE_CATEGORY: "createCrmSourceCategory",
  UPDATE_CRM_SOURCE_CATEGORY: "updateCrmSourceCategory",
  GET_CRM_SOURCE: "getCrmSource",
  CREATE_CRM_SOURCE: "createCrmSource",
  UPDATE_CRM_SOURCE: "updateCrmSource",
  GET_CRM_ENQUIRY_STATUS: "getCrmEnquiryStatus",
  CREATE_CRM_ENQUIRY_STATUS: "createCrmEnquiryStatus",
  UPDATE_CRM_ENQUIRY_STATUS: "updateCrmEnquiryStatus",
  GET_CRM_ENQUIRY_CATEGORY: "getCrmEnquiryCategory",
  CREATE_CRM_ENQUIRY_CATEGORY: "createCrmEnquiryCategory",
  UPDATE_CRM_ENQUIRY_CATEGORY: "updateCrmEnquiryCategory",
  GET_CRM_ENQUIRY_CALL_STATUS: "getCrmEnquiryCallStatus",
  CREATE_CRM_ENQUIRY_CALL_STATUS: "createCrmEnquiryCallStatus",
  UPDATE_CRM_ENQUIRY_CALL_STATUS: "updateCrmEnquiryCallStatus",
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_DETAILS:
    "getCivilProjectTaskPlanMaterialScheduleDetail",

  //Indent (Sadanand)
  UPDATE_INDENT_ITEM: "updateIndentItem",
  UPDATE_QUOTATION_DETAIL: "updateQuotationDetail",
  UPDATE_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_SEND_FOR_APPROVAL:
    "updateCivilProjectTaskPlanMaterialScheduleSendForApproval",
  GET_INDENT_APPROVAL: "getIndentApproval",
  GET_QUOTATION_APPROVAL: "getQuotationApproval",
  GET_QUOTATION_FOR_PURCHASE_ORDER: "getQuotationForPurchaseOrder",
  GET_PURCHASE_ORDER_APPROVAL: "getPurchaseOrderApproval",
  UPDATE_CIVIL_PROJECT_TASK_APPROVAL: "updateCivilProjectTask",
  GET_DIMENSION_MASTER: "getCrmDimensionMaster",
  CREATE_DIMENSION_MASTER: "createCrmDimensionMaster",
  UPDATE_DIMENSION_MASTER: "updateCrmDimensionMaster",
  GET_RELEASE_STATUS: "getCrmReleaseStatus",
  CREATE_RELEASE_STATUS: "createCrmReleaseStatus",
  UPDATE_RELEASE_STATUS: "updateCrmReleaseStatus",
  GET_CRM_SITE_PREMIUM_TYPE: "getCrmSitePremiumType",
  CREATE_CRM_SITE_PREMIUM_TYPE: "createCrmSitePremiumType",
  UPDATE_CRM_SITE_PREMIUM_TYPE: "updateCrmSitePremiumType",
  GET_CRM_SITE_STATUS: "getCrmSiteStatus",
  CREATE_CRM_SITE_STATUS: "createCrmSiteStatus",
  UPDATE_CRM_SITE_STATUS: "updateCrmSiteStatus",
  GET_SITE_INVESTOR: "getCrmSiteInvestor",
  CREATE_SITE_INVESTOR: "createCrmSiteInvestor",
  UPDATE_SITE_INVESTOR: "updateCrmSiteInvestor",
  GET_LIAISON_PROCESS_PLAN_KPI: "getLiaisonProcessPlanKpi",
  GET_PURCHASE_ORDER_PRINT: "getPurchaseOrderPrint",
  UPDATE_GRN: "updateGrn",
  GET_BLOCK_REASON: "getCrmBlockReason",
  CREATE_BLOCK_REASON: "createCrmBlockReason",
  UPDATE_BLOCK_REASON: "updateCrmBlockReason",

  //Material Stoack
  GET_PROJECT_MATERIAL_STOCK: "getProjectMaterialStock",
  GET_PROJECT_MATERIAL_STOCK_FILTER_PROJECT:
    "getProjectMaterialStockFilterProject",
  GET_PROJECT_MATERIAL_STOCK_FILTER_ITEM: "getProjectMaterialStockFilterItem",
  GET_PROJECT_MATERIAL_STOCK_FILTER_PROCESS:
    "getProjectMaterialStockFilterProcess",
  GET_PROJECT_MATERIAL_STOCK_FILTER_TASK: "getProjectMaterialStockFilterTask",

  //GRN (Sadanand)
  UPDATE_GRN_STATUS: "updateGrnStatus",
  GET_GRN_CHECK_POINT_MATERIAL: "getPrcGrnCheckPointMaterial",
  CREATE_GRN_CHECK_POINT_MATERIAL: "createPrcGrnCheckPointMaterial",
  UPDATE_GRN_CHECK_POINT_MATERIAL: "updatePrcGrnCheckPointMaterial",
  GET_CHECK_POINT: "getPrcGrnCheckPoint",
  CREATE_CHECK_POINT: "createPrcGrnCheckPoint",
  UPDATE_CHECK_POINT: "updatePrcGrnCheckPoint",
  GET_PRC_GRN_CHECK_POINT_MATERIAL: "getPrcGrnCheckPointMaterial",
  UPDATE_GRN_INSPECTION: "updateGrnInspection",
  GET_INDENT_FILTER_REQUESTED_BY: "getIndentFilterRequestedBy",
  GET_CIVIL_PROJECT_APPROVAL: "getCivilProjectApproval",
  UPDATE_CIVIL_TASK_PLAN_APPROVAL: "updateCivilTaskPlanApproval",
  CREATE_CIVIL_PROJECT_BILL_LABOUR: "createCivilProjectBillLabour",
  CREATE_CIVIL_PROJECT_BILL_CONTRACT: "createCivilProjectBillContract",
  GET_CIVIL_PROJECT_BILL: "getCivilProjectBill",
  GET_CIVIL_PROJECT_BILL_DETAIL: "getCivilProjectBillDetail",
  UPDATE_CIVIL_PROJECT_BILL_DETAIL: "updateCivilProjectBillDetail",
  CIVIL_PROJECT_BILL_SEND_FOR_APPROVAL: "civilProjectBillSendForApproval",

  //Civil Bill (Sadanand)
  UPDATE_CIVIL_PROJECT_BILL_GENERATE: "updateCivilProjectBillGenerate",
  GET_CIVIL_PROJECT_BILL_ACCOUNT: "getCivilProjectBillAccount",
  UPDATE_CIVIL_PROJECT_BILL_ACCOUNT_DETAIL:
    "updateCivilProjectBillAccountDetail",
  UPDATE_CIVIL_PROJECT_BILL_ACCOUNT_APPROVE:
    "updateCivilProjectBillAccountApprove",
  UPDATE_CIVIL_PROJECT_BILL_ACCOUNTED: "updateCivilProjectBillAccounted",
  UPDATE_CIVIL_PROJECT_BILL_ACCOUNTED_REVERSE:
    "updateCivilProjectBillAccountedReverse",
  GET_CIVIL_PROJECT_BILL_FILTER_PROJECT: "getCivilProjectBillFilterProject",
  GET_CIVIL_PROJECT_BILL_FILTER_COMPANY: "getCivilProjectBillFilterCompany",
  GET_CIVIL_PROJECT_BILL_FILTER_VENDOR: "getCivilProjectBillFilterVendor",
  GET_CIVIL_PROJECT_BILL_FILTER_CATEGORY: "getCivilProjectBillFilterCategory",
  GET_LIASION_DOCUMENATAION_TASK_PLAN_EXPORT:
    "getLiaisonDocumentationTaskPlanExport",
  UPDATE_CIVIL_PROJECT_BILL_STATUS: "updateCivilProjectBillStatus",
  CIVIL_PROJECT_BILL_DELETE: "civilProjectBillDelete",

  //Store (Material Request)
  GET_PROJECT_MATERIAL_REQUEST: "getPrcProjectMaterialRequest",
  CREATE_PROJECT_MATERIAL_REQUEST: "createPrcProjectMaterialRequest",
  GET_PROJECT_MATERIAL_REQUEST_DETAIL: "getPrcProjectMaterialRequestDetail",
  UPDATE_PROJECT_MATERIAL_REQUEST_STATUS:
    "updatePrcProjectMaterialRequestStatus",
  UPDATE_PROJECT_MATERIAL_REQUEST: "updatePrcProjectMaterialRequest",

  GET_WBS_FOR_MATERIAL_REQUEST: "getWbsForMaterialRequest",
  GET_ACTIVITY_FOR_MATERIAL_REQUEST: "getActivityForMaterialRequest",
  GET_CLOSING_STOCK_FOR_MATERIAL_REQUEST: "getClosingStockForMaterialRequest",
  GET_PROJECT_MATERIAL_REQUEST_FILTER_PROJECT:
    "getPrcProjectMaterialRequestFilterProject",
  GET_PROJECT_MATERIAL_REQUEST_FILTER_PROCESS:
    "getPrcProjectMaterialRequestFilterProcess",
  CREATE_MATERIAL_RETURN_ISSUE_RETURN: "createMaterialRequestIssueReturn",

  GET_MATERIAL_REQUEST_INDENT_INFO: "getMaterialRequestIndentInfo",
  GET_STOCK_TRANSFER_MATERIAL: "getStocktransferProjectMaterial",
  GET_PROJECT_MATERIAL_REQUEST_INDENT: "getPrcProjectMaterialRequestIndent",

  //Material Return
  GET_MATERIAL_REQUEST_ISSUE_RETURN: "getMaterialRequestIssueReturn",
  GET_MATERIAL_REQUEST_ISSUE_RETURN_DETAIL:
    "getMaterialRequestIssueReturnDetail",
  UPDATE_MATERIAL_REQUEST_ISSUE_RETURN_STATUS:
    "updateMaterialRequestIssueReturnStatus",
  GET_MATERIAL_REQUEST_ISSUE_RETURN_FILTER_PROJECT:
    "getMaterialRequestIssueReturnFilterProject",
  GET_MATERIAL_REQUEST_ISSUE_RETURN_FILTER_PROCESS:
    "getMaterialRequestIssueReturnFilterProcess",
  GET_MATERIAL_REQUEST_ISSUE_RETURN_FILTER_MATERIAL:
    "getMaterialRequestIssueReturnFilterMaterial",
  GET_MATERIAL_REQUEST_ISSUE_RETURN_FILTER_TASK:
    "getMaterialRequestIssueReturnFilterTask",

  //Stock Transfer (Sadanand)
  GET_STOCK_TRANSFER: "getStockTransfer",
  CREATE_STOCK_TRANSFER: "createStockTransferRequest",
  GET_PROJECT_MATERIAL_STOCK_TRANSFER: "getProjectMaterialStockQuantiy",
  GET_PROJECT_MATERIAL_STOCK_TRANSFER_DETAIL: "getStockTransferDetail",
  UPDATE_PROJECT_MATERIAL_STOCK_STATUS: "updateStockTransferStatus",
  CREATE_PROJECT_MATERIAL_STOCK_REQUEST: "updateStockTransferRequest",
  GET_STOCK_TRANSFER_SOURCE_PROJECT_FILTER:
    "getStockTransferSourceProjectFilter",
  GET_STOCK_TRANSFER_DESTINATION_PROJECT_FILTER:
    "getStockTransferDestinationProjectFilter",
  GET_STOCK_TRANSFER_MATERIAL_PROJECT_FILTER: "getStockTransferMaterialFilter",
  GET_CIVIL_PROJECT_FOR_ACTUAL_ENTRY: "getCivilProjectForActualEntry",
  GET_CIVIL_PROJECT_WBS_FOR_ACTUAL_ENTRY: "getCivilProjectWbsForActualEntry",
  GET_CIVIL_PROJECT_ACTIVITY_FOR_ACTUAL_ENTRY:
    "getCivilProjectActivityForActualEntry",
  GET_STOCK_TRANSFER_DESTINATION_PROJECT: "getStockTransferDestinationProject",
  GET_ENQUIRY_FOLLOW_STATUS: "getEnquiryFollowStatus",
  GET_LIAISON_PROCESS_PLAN_EXPORT: "getLiaisonProcessPlanExport",
  GET_PROJECT_MATERIAL_REQUEST_FILTER_MATERIAL:
    "getPrcProjectMaterialRequestFilterMaterial",
  GET_PROJECT_MATERIAL_REQUEST_FILTER_TASK:
    "getPrcProjectMaterialRequestFilterTask",

  //Stock Transfer
  GET_MATERIAL_FOR_MATERIAL_REQUEST: "getMaterialForMaterialRequest",
  GET_CLOSING_STOCK_FOR_MATERIAL_STOCK_TRANSFER:
    "getClosingStockForStockTransfer",
  CREATE_STOCK_TRANSFER_REQUEST: "createStockTransferRequest",
  GET_STOCK_TRANSFER_FILTER_FROM_PROJECT: "getStockTransferFilterFromProject",
  GET_STOCK_TRANSFER_FILTER_TO_PROJECT: "getStockTransferFilterToProject",
  GET_STOCK_TRANSFER_FILTER_FROM_PROCESS: "getStockTransferFilterFromProcess",
  GET_STOCK_TRANSFER_FILTER_TO_PROCESS: "getStockTransferFilterToProcess",
  GET_STOCK_TRANSFER_FILTER_FROM_TASK: "getStockTransferFilterFromTask",
  GET_STOCK_TRANSFER_FILTER_TO_TASK: "getStockTransferFilterToTask",
  GET_STOCK_TRANSFER_FILTER_TO_MATERIAL: "getStockTransferFilterMaterial",

  //Civil Setting
  GET_CIVIL_SETTING: "getCivilSetting",
  CREATE_CIVIL_SETTING: "createCivilSetting",
  UPDATE_CIVIL_SETTING: "updateCivilSetting",

  //Project Setting
  GET_CIVIL_PROJECT_SETTING: "getCivilProjectSetting",
  CREATE_CIVIL_PROJECT_SETTING: "createCivilProjectSetting",
  GET_CIVIL_PROJECT_SETTING_DETAILS: "getCivilProjectSettingDetail",
  UPDATE_CIVIL_SETTING_STATUS: "updateCivilProjectSettingStatus",
  UPDATE_CIVIL_PROJECT_SETTING: "updateCivilProjectSetting",
  GET_CIVIL_PROJECT_SETTING_FILTER_PROJECT:
    "getCivilProjectSettingFilterProject",
  GET_CIVIL_PROJECT_SETTING_FILTER_CIVIL:
    "getCivilProjectSettingFilterCivilSetting",
  GET_PROJECT_DOCUMENT_REPOSITORY_FILTER_PROJECT:
    "getProjectDocumentRepositoryFilterProject",
  GET_CRM_CHANNEL_PARTNER: "getCrmMeetingChannelPartner",
  GET_CRM_CLIENT: "getCrmMeetingClient",
  GET_CRM_STM: "getCrmMeetingStm",

  //Appraisal (Sadanand)
  GET_EMPLOYEE_FOR_APPRAISAL: "getEmployeeForAppraisal",
  GET_EMPLOYEE_APPRAISAL_FILTER_EMPLOYEE: "getEmployeeAppraisalFilterEmployee",
  GET_EMPLOYEE_APPRAISAL_FILTER_APPRAISAL:
    "getEmployeeAppraisalFilterAppraisal",
  GET_EMPLOYEE_APPRAISAL_DETAIL: "getEmployeeAppraisalDetail",
  GET_EMPLOYEE_APPRAISAL_FILTER_DEPARTMENT:
    "getEmployeeAppraisalFilterDepartment",
  GET_EMPLOYEE_APPRAISAL_FILTER_DESGINATION:
    "getEmployeeAppraisalFilterDesignation",
  GET_EMPLOYEE_DEPARTMENT_FOR_APPRAISAL: "getEmployeeDepartmentForAppraisal",
  GET_CRM_MEETING_FILTER_PROJECT: "getCrmMeetingFilterProject",
  GET_CRM_MEETING_FILTER_HOSTED_BY: "getCrmMeetingFilterHostedBy",
  UPLOAD_VENDOR_RERA: "uploadVendorRera",

  //Purchase Invoice (Sadanand)
  CREATE_PURCHASE_INVOICE: "createPurchaseInvoice",
  GET_PURCHASE_INVOICE: "getPurchaseInvoice",
  GET_PURCHASE_INVOICE_PRINT: "getPurchaseInvoicePrint",
  GET_PURCHASE_INVOICE_DETAIL: "getPurchaseInvoiceDetail",
  UPDATE_PURCHASE_INVOICE_STATUS: "updatePurchaseInvoiceStatus",
  UPDATE_PURCHASE_INVOICE: "updatePurchaseInvoice",
  UPDATE_PURCHASE_INVOICE_ACCOUNTED: "updatePurchaseInvoiceAccounted",
  UPDATE_PURCHASE_INVOICE_ACCOUNTED_REVERSE:
    "updatePurchaseInvoiceAccountedReverse",
  PURCHASE_INVOICE_DELETE: "purchaseInvoiceDelete",
  PURCHASE_INVOICE_ITEM_DELETE: "purchaseInvoiceItemDelete",
  PURCHASE_INVOICE_CHARGE_DELETE: "purchaseInvoiceChargeDelete",
  GET_PURCHASE_INVOICE_FILTER_PROJECT: "getPurchaseInvoiceFilterProject",
  GET_PURCHASE_INVOICE_FILTER_VENDOR: "getPurchaseInvoiceFilterVendor",
  GET_PURCHASE_INVOICE_FILTER_COMPANY: "getPurchaseInvoiceFilterCompany",
  GET_GRN_DEBIT_NOTE_ITEM_FOR_PURCHASE_INVOICE:
    "getGrnDebitNoteItemForPurchaseInvoice",
  GET_PURCHASE_ORDER_FOR_INVOICE: "getPurchaseOrderForInvoice",
  GET_GRN_FOR_PURCHASE_INVOICE: "getGrnForPurchaseInvoice",

  //Purchase Invoice Approval (Sadanand)
  GET_PURCHASE_INVOICE_ACCOUNT: "getPurchaseInvoiceAccount",

  //Land and Site Registration API
  GET_BD_REGISTRATION_PAYMENT_PROCESS: "getBdRegistrationPaymentProcess",
  GET_BD_REGISTRATION_PAYMENT_DEED_TYPE: "getBdRegistrationPaymentDeedType",
  GET_REGISTRATION_PAYMENT_SURVEY_INFO: "getBdRegistrationPaymentSurveyInfo",
  GET_BD_REGISTRATION_PAYMENT_SITE_INFO: "getBdRegistrationPaymentSiteInfo",
  CREATE_BD_REGISTRATION_PAYMENT: "createBdRegistrationPayment",
  ADD_BD_REGISTRATION_PAYMENT_SITE_ITEM: "addBdRegistrationPaymentItem",
  GET_REGISTRATION_PAYMENT: "getBdRegistrationPayment",
  GET_BD_REGISTRATION_PAYMENT_DETAIL: "getBdRegistrationPaymentDetail",
  DELETE_BD_REGISTRATION_PAYMENT_ITEM: "deleteBdRegistrationPaymentItem",
  DELETE_BD_REGISTRATION_PAYMENT: "deleteBdRegistrationPayment",
  UPDATE_BD_REGISTRATION_PAYMENT_STATUS: "updateBdRegistrationPaymentStatus",
  UPDATE_BD_REGISTRATION_PAYMENT_ISSUE: "updateBdRegistrationPaymentIssue",
  UPDATE_BD_REGISTRATION_PAYMENT_ITEM_ACTUAL_AMOUNT:
    "updateBdRegistrationPaymentItemActualAmount",
  UPDATE_BD_REGISTRATION_PAYMENT_SETTLEMENT_SETTLE:
    "updateBdRegistrationPaymentSettlementSettle",
  UPDATE_BD_REGISTRATION_PAYMENT_SETTLEMENT_APPROVAL:
    "updateBdRegistrationPaymentSettlementApproval",
  GET_BD_REGISTRATION_PAYMENT_FILTER_PROJECT_LAND:
    "getBdRegistrationPaymentFilterProjectLand",
  GET_BD_REGISTRATION_PAYMENT_FILTER_PROJECT_SITE:
    "getBdRegistrationPaymentFilterProjectSite",
  GET_BD_REGISTRATION_PAYMENT_FILTER_COMPANY:
    "getBdRegistrationPaymentFilterCompany",

  //Delivery Challan (Sadanand)
  CREATE_DELIVERY_CHALLAN: "createDeliveryChallan",
  GET_DELIVERY_CHALLAN: "getDeliveryChallan",
  GET_DELIVERY_CHALLAN_DETAIL: "getDeliveryChallanDetail",
  UPDATE_DELIVERY_CHALLAN: "updateDeliveryChallan",
  UPDATE_DELIVERY_CHALLAN_STATUS: "updateDeliveryChallanStatus",
  GET_DELIVERY_CHALLAN_APPROVED: "getDeliveryChallanApproved",
  GET_DELIVERY_CHALLAN_PRINT: "getDeliveryChallanPrint",
  DELIVERY_CHALLAN_ITEM_DELETE: "deliveryChallanItemDelete",
  DELIVERY_CHALLAN_DELETE: "deliveryChallanDelete",
  GET_STOCK_TRANSFER_FOR_DELIVERY_CHALLAN: "getStockTransferForDeliveryChallan",
  GET_DELIVER_CHALLAN_FILTER_TO_PROJECT: "getDeliveryChallanFilterToProject",
  GET_DELIVER_CHALLAN_FILTER_TO_COMPANY: "getDeliveryChallanFilterToCompany",

  //Channel Partner
  CREATE_CHANNEL_PARTNER: "createChannelPartner",
  GET_CHANNEL_PARTNER: "getChannelPartner",
  CHECK_VENDOR_GST: "checkVendorGst",
  CHECK_VENDOR_PAN: "checkVendorPan",
  CHECK_VENDOR_RERA: "checkVendorRera",
  CHECK_VENDOR_MSME: "checkVendorMsme",

  //Process Fee
  GET_PROCESS_FEE_ITEM: "getProcessFeeItem",
  CREATE_PROCESS_FEE_ITEM: "createProcessFeeItem",
  GET_BD_FEES_ITEM: "getBdFeesItem",
  UPDATE_PROCESS_FEE_ITEM: "updateProcessFeeItem",

  //Notification(Sadanand)
  GET_PENDING_TODO: "getPendingToDo",
  GET_PENDING_LIAISON_PROCESS_PLAN: "getPendingLiaisonProcessPlan",
  GET_PROJECT_TASK_PLAN_PENDING: "getProjectTaskPlanPending",

  //Govt fees
  GET_GOVT_FEES: "getGovernmentFees",
  GET_GOVT_FEES_PROCESS_PLAN: "getGovernmentFeesProcessPlan",
  CREATE_GOVERNMENT_FEES: "createGovernmentFees",
  GET_GOVERNMENT_DETAIL: "getGovernmentFeesDetail",
  UPDATE_GOVT_FEES_STATUS: "updateGovernmentFeesStatus",
  ADD_GOVT_FEES_ITEM: "addGovernmentFeesItem",
  GET_GOVT_FEES_PROCESS_ITEM: "getGovernmentFeesProcessItem",
  UPDATE_GOVERNMENT_FEES_ISSUE: "updateGovernmentFeesIssue",
  UPDATE_GOVERNMENT_FEES_PAID: "updateGovernmentFeesPaidAmount",
  DELETE_GOVERNMENT_FEES_ITEM: "deleteGovernmentFeesItem",
  GET_GOVT_FEES_ITEM: "getGovernmentFeesItem",
  GET_DELIVERY_CHALLAN_FILTER_FROM_PROJECT:
    "getDeliveryChallanFilterFromProject",
  GET_DELIVERY_CHALLAN_FILTER_FROM_COMPANY:
    "getDeliveryChallanFilterFromCompany",
  GET_GOVT_FEES_PROJECT_FILTER: "getGovernmentFeesFilterProject",
  GET_GOVT_FEES_PROCESS_FILTER: "getGovernmentFeesFilterProcess",
  GET_GOVT_FEES_FILTER_COMPANY: "getGovernmentFeesFilterCompany",
  GET_GOVT_FEE_SURVEY_NUMBER: "getGovernmentFeesProcessPlan",
  CREATE_BD_FEES_ITEM: "createBdFeesItem",
  UPDATE_BD_FEES_ITEM: "updateBdFeesItem",
  GET_BD_FEES_ITEM_AVAILABLE: "getBdFeesItemAvailable",
  GET_GOVT_FEES_TASK_PLAN: "getGovernmentFeesTaskPlan",

  //Civil and Project Bill (Sadanand)
  GET_PROJECT_BILL_ADVANCE_DEDUCTION_PERCENTAGE:
    "getProjectBillAdvanceDeductionPercentage",
  GET_PROJECT_BILL_RETENTION_PERCENTAGE: "getProjectBillRetentionPercentage",

  //Retention Percentage
  GET_BILL_CATEGORY: "getBillCategory",
  GET_RETENTION_PERCENTAGE: "getRetentionPercentage",
  CREATE_RETENTION_PERCENTAGE: "createRetentionPercentage",
  GET_RETENTION_PERCENTAGE_FILTER_BILL_CATEGORY:
    "getRetentionPercentageFilterBillCategory",
  UPDATE_RETENTION_PERCENTAGE: "updateRetentionPercentage",

  //Role Fun
  UPDATE_USER_ROLE_FUNCTIONALITY_STATUS: "updateUserRoleFunctionalityStatus",
  GET_USER_ROLE_FUNCTIONALITY_FILTER_DEPARTMENT:
    "getUserRoleFunctionalityFilterDepartment",
  GET_USER_ROLE_FUNCTIONALITY_FILTER_DESIGNATION:
    "getUserRoleFunctionalityFilterDesignation",
  GET_BD_LAND_DISPUTE_FILTER_ACCOUNTABLE: "getBdLandDisputeFilterAccountable",
  GET_COURT_CASE_FILTER_ACCOUNTABLE: "getCourtCaseFilterAccountable",

  //Vendor Ledger (Nithish)
  GET_VENDOR_LADGER: "getVendorLedger",
  CREATE_VENDOR_LADGER: "createVendorLedger",
  GET_VENDOR_LADGER_DETAILS: "getVendorLedgerDetail",
  GET_VENDOR_FOR_LEDGER: "getVendorForLedger",
  GET_VENDOR_LADGER_ITEM: "getVendorLedgerItem",
  GET_VENDOR_LADGER_FILTER_VENDOR: "getVendorLedgerFilterVendor",
  GET_VENDOR_LADGER_FILTER_COMPANY: "getVendorLedgerFilterCompany",
  GET_VENDOR_LADGER_ITEM_FILTER_VENDOR: "getVendorLedgerItemFilterVendor",
  GET_VENDOR_LADGER_ITEM_FILTER_COMPANY: "getVendorLedgerItemFilterCompany",
  GET_VENDOR_LADGER_ITEM_FILTER_PROJECT: "getVendorLedgerItemFilterProject",
  GET_VENDOR_LADGER_ITEM_FILTER_CATEGORY: "getVendorLedgerItemForCategory",
  GET_VENDOR_ACCOUNT_FOR_PAYMENT_REQUEST: "getVendorAccountForPaymentRequest",

  //Vendor Payment Request (Sadanand)
  CREATE_PAYMENT_REQUEST: "createPaymentRequest",
  CREATE_PAYMENT_REQUEST_SINGLE: "createPaymentRequestSingle",
  GET_PAYMENT_REQUEST: "getPaymentRequest",
  GET_PAYMENT_REQUEST_FILTER_PROJECT: "getPaymentRequestFilterProject",
  GET_PAYMENT_REQUEST_FILTER_VENDOR: "getPaymentRequestFilterVendor",
  GET_PAYMENT_REQUEST_FILTER_CATEGORY: "getPaymentRequestFilterCategory",
  GET_PAYMENT_REQUEST_FILTER_COMPANY: "getPaymentRequestFilterComapny",
  UPDATE_PAYMENT_REQUEST_STATUS: "updatePaymentRequestStatus",
  UPDATE_PAYMENT_REQUEST_STATUS_PAY: "updatePaymentRequestStatusPay",
  PAYMENT_REQUEST_DELETE: "paymentRequestDelete",
  GET_PAYMENT_REQUEST_DETAIL: "getPaymentRequestDetail",
  GET_VENDOR_PAYMENT_INFO_FOR_PAYMENT_REQUEST:
    "getVendorPaymentInfoForPaymentRequest",
  GET_VENDOR_PO_WO_FOR_PAYMENT_REQUEST: "getVendorPoWoForPaymentRequest",
  GET_CATEGORY_FOR_PAYMENT_REQUEST: "getCategoryForPaymentRequest",
  GET_PROJECT_FOR_PAYMENT_REQUEST: "getProjectForPaymentRequest",
  GET_COMPANY_FOR_PAYMENT_REQUEST: "getCompanyForPaymentRequest",
  GET_VENDOR_FOR_PAYMENT_REQUEST: "getVendorForPaymentRequest",

  //Vendor Payment Request Account (Sadanand)
  GET_PAYMENT_REQUEST_APPROVAL: "getPaymentRequestApproval",
  GET_PAYMENT_REQUEST_APPROVAL_FILTER_PROJECT:
    "getPaymentRequestApprovalFilterProject",
  GET_PAYMENT_REQUEST_APPROVAL_FILTER_VENDOR:
    "getPaymentRequestApprovalFilterVendor",
  GET_PAYMENT_REQUEST_APPROVAL_FILTER_COMPANY:
    "getPaymentRequestApprovalFilterComapny",
  GET_PAYMENT_REQUEST_APPROVAL_FILTER_CATEGORY:
    "getPaymentRequestApprovalFilterCategory",
  GET_ACCOUNT_CHEQUE_NUMBER: "getAccountChequeNumber",
  GET_PAYMENT_REQUEST_PAID: "getPaymentRequestPaid",
  GET_PAYMENT_REQUEST_PAID_DETAIL: "getPaymentRequestPaidDetail",
  GET_PAYMENT_REQUEST_PAID_FILTER_COMPANY: "getPaymentRequestPaidFilterCompany",
  UPDATE_PAYMENT_REQUEST_ACCOUNTED: "updatePaymentRequestAccounted",
  UPDATE_PAYMENT_REQUEST_ACCOUNTED_REVERSE:
    "updatePaymentRequestAccountedReverse",
  UPDATE_PAYMENT_REQUEST_REALIZE: "updatePaymentRequestRealize",
  GET_PAYMENT_REQUEST_PRINT: "getPaymentRequestPrint",
  GET_PAYMENT_REQUEST_PRINTS: "getPaymentRequestPrints",

  //Purchase return (Nikhil)
  GET_PURCHASE_RETURN: "getPurchaseReturn",
  CREATE_PURCHASE_RETURN: "createPurchaseReturn",
  GET_PURCHASE_RETURN_DETAIL: "getPurchaseReturnDetail",
  UPDATE_PURCHASE_RETURN_STATUS: "updatePurchaseReturnStatus",
  UPDATE_PURCHASE_RETURN: "updatePurchaseReturn",
  GET_PURCHASE_INVOICE_FOR_RETURN: "getPurchaseInvoiceForReturn",
  GET_PURCHASE_INVOICE_ITEM_FOR_RETURN: "getPurchaseInvoiceItemForReturn",
  PURCHASE_RETURN_DELETE: "purchaseReturnDelete",
  PURCHASE_RETURN_ITEM_DELETE: "purchaseReturnItemDelete",
  GET_PURCHASE_RETURN_FILTER_PROJECT: "getPurchaseReturnFilterProject",
  GET_PURCHASE_RETURN_FILTER_COMPANY: "getPurchaseReturnFilterCompany",
  GET_PURCHASE_RETURN_FILTER_VENDOR: "getPurchaseReturnFilterVendor",
  GET_FELLOW_UP: "getCrmFollowUp",
  GET_CRM_FOLLOW_UP_FILTER_ASSIGNED_TO: "getCrmFollowUpFilterFollowUpBy",

  //TDS master
  CREATE_TDS_DEDUCTION: "createTdsDeduction",
  GET_TDS_DEDUCTION: "getTdsDeduction",
  UPDATE_TDS_DEDUCTION: "updateTdsDeduction",
  GET_TDS_DEDUCTION_FILTER_CATEGORY: "getTdsDeductionFilterCategory",
  GET_TDS_DEDUCTION_FILTER_VENDOR: "getTdsDeductionFilterVendor",
  GET_PROJECT_MATERIAL_STOCK_TRANSACTION: "getProjectMaterialStockTransaction",
  UPDATE_PROJECT_MATERIAL_STOCK_OPENING_QUANTITY:
    "updateProjectMaterialStockOpeningQuantity",

  // Expenses Invoice (Sadanand)
  GET_ITEM_FOR_EXPENSES: "getItemForExpenses",
  GET_COST_CENTER: "getCostCenter",
  CREATE_EXPENSES_INVOICE: "createExpensesInvoice",
  GET_EXPENSES_INVOICE: "getExpensesInvoice",
  GET_EXPENSES_INVOICE_DETAIL: "getExpensesInvoiceDetail",
  UPDATE_EXPENSES_INVOICE: "updateExpensesInvoice",
  EXPENSES_INVOICE_DELETE: "expensesInvoiceDelete",
  EXPENSES_INVOICE_ITEM_DELETE: "expensesInvoiceItemDelete",
  EXPENSES_INVOICE_DOCUMENT_DELETE: "expensesInvoiceDocumentDelete",
  UPDATE_EXPENSES_INVOICE_STATUS: "updateExpensesInvoiceStatus",
  GET_EXPENSES_INVOICE_FILTER_PROJECT: "getExpensesInvoiceFilterProject",
  GET_EXPENSES_INVOICE_FILTER_VENDOR: "getExpensesInvoiceFilterVendor",
  GET_EXPENSES_INVOICE_FILTER_COMPANY: "getExpensesInvoiceFilterCompany",
  GET_EXPENSES_INVOICE_FILTER_COST_CENTER: "getExpensesInvoiceFilterCostCenter",
  UPDATE_EXPENSES_INVOICE_ACCOUNTED: "updateExpensesInvoiceAccounted",
  UPDATE_EXPENSES_INVOICE_ACCOUNTED_REVERSE:
    "updateExpensesInvoiceAccountedReverse",

  //HR Module User Bata
  GET_USER_BATA: "getUserBata",
  GET_USER_BATA_FILTER_USER: "getUserBataFilterUser",
  CREATE_USER_BATA: "createUserBata",
  UPDATE_USER_BATA: "updateUserBata"
};
export const ApiService = axios.create({
  baseURL: BaseUrl + "v2/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded"
  }
});
export const ErpApiService = axios.create({
  baseURL: BaseUrl + "v2/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded"
  }
});
export const FileUploadService = axios.create({
  baseURL: BaseUrl + "v2/",
  headers: {
    // 'Accept' : 'application/json',
    // 'Content-Type' : 'application/x-www-form-urlencoded',
  }
});
//Store Module
export const StoreService = axios.create({
  baseURL: BaseUrl + "store/v2/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded"
  }
});

//Account Module
export const AccountService = axios.create({
  baseURL: BaseUrl + "accounts/v2/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded"
  }
});

export const AccountUploadService = axios.create({
  baseURL: BaseUrl + "accounts/v2/",
  headers: {
    // 'Accept' : 'application/json',
    // 'Content-Type' : 'application/x-www-form-urlencoded',
  }
});

/*export const fileDownloadService = axios.create({
  baseURL: BaseUrl + "v2/",
  responseType: "blob",
  headers: {
    Accept: "application/pdf",
  },
});*/

export const fileDownloadService = axios.create({
  baseURL: DocumentUrl,
  responseType: "blob",
  headers: {
    Accept: "application/pdf"
  }
});

// export const fileDownloadService = axios.create({
//   baseURL: BaseUrl + "v2/",
//   responseType: "blob",
//   headers: {
//     Accept: "application/pdf",
//   },
// });

export const SetAuthToken = (token) => {
  if (token) {
    ApiService.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    delete ApiService.defaults.headers.common["Authorization"];
  }
};
export const ApiStatus = {
  OTP_SENT: "OTP sent"
};
